import React from "react";
import "./App.css";
import "./index.css";
import WebService from "./Service";
import Utils from "./Utils";


export default
class JoinUs extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            regions : [],
            members : [],
        };
    }
    componentDidMount() {
        WebService.regions().then((regions) => {
            regions.json().then((regions) => {
                this.setState({regions: regions});
            });
        });

        if(Utils.isAdmin(Utils.me())) {
            WebService.users().then((members) => {
                members.json().then((members) => {
                    this.setState({members: members.filter((member) => { return Utils.isMember(member) })})
                });
            });
        }


    }
    
    /* Carte visuel (membres, évènements) */

    render() {
        let me = Utils.me();
        return  (
            this.state.regions.map((region) => {
                return (
                    <div key={region.region} className="block mt-10 bg-frenchBlack/75 rounded hover:-translate-y-2 transition duration-500 ease-out-in ">
                    <hr className="border-frenchBrown border-2 w-full "/>
                        <div className="flex flex-col items-center ">
                                <h3 className="font-bold text-center text-xl text-frenchWhite p-3 ">{region.region}</h3>
                                {/* Contact */}
                                {region.contact &&
                                    <div className="flex flex-col items-center">
                                        <p className="text-center text-frenchWhite"> Contactez {region.contact.firstName + " " + region.contact.lastName.toUpperCase()}</p>
                                        {region.contact.phone &&
                                            <p className="text-center text-frenchWhite">Téléphone : {Utils.formatPhone(region.contact.phone)}</p>
                                        }
                                        {/* {region.contact.mail &&
                                            <p className="text-center text-frenchWhite">Email : {region.contact.mail}</p>
                                        } */}
                                   </div>
                               }
                               {Utils.isAdmin(me) &&
                                    // Mettre à jour le responsable de region
                                    <div className="flex items-center">
                                        {/* Select user */}
                                        <select className="w-2/3 rounded-lg bg-frenchWhite text-frenchBlack" name="user" id="user">
                                            <option value="">Sélectionner un membre</option>
                                            {this.state.members.map((member) => {
                                                return (
                                                    <option key={member.id} value={member.id}>{member.firstName + " " + member.lastName}</option>
                                                );
                                            })}
                                        </select>

                                        {/* Validate Button */}
                                        <button className="bg-frenchWhite text-frenchBlack rounded-lg p-2 m-2" onClick={() => {
                                            let user = document.getElementById("user").value;
                                            if(user === "") {
                                                return;
                                            }
                                            WebService.setRegionContact(user, region.region).then((modifiedRegion) => {
                                                if(!modifiedRegion.ok) {
                                                    return;
                                                }
                                                modifiedRegion.json().then((region) => {
                                                
                                                    let regions = this.state.regions;
                                                    regions.find((r) => { return r.region === region.region }).contact = this.state.members.find((m) => { return m.id === user });
                                                    this.setState({regions: regions});
                                                });
                                            });
                                        }}>Faire responsable</button>
                                    </div>
                               }
                        </div>
                    </div>
                    );
            })
        );
    }
        
}

