import React from "react";
import "./App.css";
import "./index.css";

import WebService from "./Service.js";

import {Page} from './App.js';

import loader from "./images/loader.gif";


export default
class Login extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            message: "",
            messageColor: "text-badColor",
            form_mode: "CONNEXION",
            isLoading: false,
        };
        this.loginFormSubmit = this.loginFormSubmit.bind(this);
    }

    componentDidMount() {
        // Get URL parameter "confirm"
        let urlParams = new URLSearchParams(window.location.search);
        let confirm = urlParams.get('confirm');

        if (confirm ) {
            let request = WebService.confirmMail(confirm);
            request.then((response) => {
                if (response.status === 200) {
                    this.setState({message: "Votre e-mail est désormais confirmée", messageColor: "text-goodColor"});
                } else {
                    this.setState({message: "Erreur lors de la confirmation de votre compte.", messageColor: "text-badColor"});
                }
            });
        }

        // Get URL parameter "reset"
        let reset = urlParams.get('reset');
        if (reset) {
            if (reset === "ask") {
                this.setState({form_mode: "ASK_RESET"});
            } else if(reset === "type") {
                // RESET WITH TOKEN
                this.setState({form_mode: "TYPE"});

                let token = urlParams.get('token');
                if(!token) {
                    this.setState({message: "Erreur lors de la réinitialisation de votre mot de passe.", messageColor: "text-badColor"});
                }
            }
        }



        this.setState({actionConfirmed: true});

    }


    loginFormSubmit(event) {
 

        event.preventDefault();

        if(this.state.form_mode === "CONNEXION") {
            let form = event.target;
            let login = form.login.value;
            let password = form.password.value;

            let request = WebService.login(login, password);
            request.then((response) => {
                if (response.status === 200) {
                    this.setState({message: "Connexion réussie.", messageColor: "text-goodColor"});
                    response.json().then((data) => {
                        if(data.token) {
                            WebService.setToken(data.token);
                            this.props.setUser();
                            this.props.setActuPage();
                            window.location.reload();
                        }
                    });
                    
                } else {
                    let message = response.status === 401 ? "Email ou mot de passe incorrect." : 
                                response.status === 404 ? "Vous n'êtes pas inscrit." 
                                                        : "Erreur lors de la connexion.";
                    this.setState({message: message, messageColor: "text-badColor"});
                }
            });
        }
        else if (this.state.form_mode === "ASK_RESET") {
            let form = event.target;
            let login = form.login.value;
            this.setState({isLoading: true});
            this.setState({message: "Envoi de l'e-mail de réinitialisation en cours...", messageColor: "text-neutralColor"});
            let request = WebService.askResetPassword(login);
            request.then((response) => {
                
                if (response.status === 200) {
                    this.setState({message: "Un e-mail de réinitialisation de mot de passe vous a été envoyé.", messageColor: "text-goodColor"});
                } else {
                    this.setState({message: "Erreur lors de l'envoi de l'e-mail.", messageColor: "text-badColor"});
                }
                this.setState({isLoading: false});
            });
        } else if (this.state.form_mode === "TYPE") {
            let form = event.target;
            let password = form.password.value;
            let password_confirm = form.password_confirm.value;
            if(password !== password_confirm) {
                this.setState({message: "Les mots de passe ne correspondent pas.", messageColor: "text-badColor"});
                return;
            }

            let token = new URLSearchParams(window.location.search).get('token');

            this.setState({isLoading: true});
            this.setState({message: "Réinitialisation de votre mot de passe en cours...", messageColor: "text-neutralColor"});
            let request = WebService.resetPassword(token, password);
            request.then((response) => {
                if (response.status === 200) {
                    this.setState({message: "Votre mot de passe a été réinitialisé.", messageColor: "text-goodColor"});
                    setTimeout(() => {
                        window.location.href = "/"+Page.Login;
                    }, 2000);
                } else {
                    this.setState({message: "Erreur lors de la réinitialisation de votre mot de passe.", messageColor: "text-badColor"});
                }
                this.setState({isLoading: false});
            });
        }
    }

    render() { 
        return (
            <div className="h-screen">
                <form className="bg-frenchBlack/75 inline-block text-frenchWhite text-left  border-frenchBrown border-2 rounded-md " onSubmit={this.loginFormSubmit}>
                    <div>
                        <h3 className="text-center text-xl text-frenchWhite p-3 bg-frenchBrown">{this.state.form_mode === "CONNEXION" ? "Connexion" : "Mot de passe oublié"}</h3>
                    </div>
                    <div>
                        <div className="">
                            {(this.state.form_mode === "CONNEXION" || this.state.form_mode === "ASK_RESET") &&
                            <div className="p-5 flex flex-col">
                                <label htmlFor="login">Email</label>
                                <input className="p-1 rounded-sm text-frenchBlack" type="email" id="login" name="login" placeholder="exemple@gmail.com" autoComplete="username"/>
                            </div>
                            }
                            {(this.state.form_mode === "CONNEXION" || this.state.form_mode === "TYPE") &&
                                <div className="p-5 flex flex-col">
                                    <label htmlFor="password">Mot de passe</label>
                                    <input className="p-1 rounded-sm text-frenchBlack" type="password" id="password" name="password" placeholder="******" autoComplete="new-password"/>
                                </div>
                            }
                            {this.state.form_mode === "TYPE" &&
                                <div className="p-5 flex flex-col">
                                    <label htmlFor="password_confirm">Confirmez le mot de passe</label>
                                    <input className="p-1 rounded-sm text-frenchBlack" type="password" id="password_confirm" name="password_confirm" placeholder="******" autoComplete="new-password"/>
                                </div>
                            }
                        </div>
                        <div className="align-center p-5 justify-center">
                            <button className="bg-frenchBrown rounded p-2 px-4" type="submit">
                                {this.state.form_mode === "CONNEXION" ? "Se connecter" : this.state.form_mode === "ASK_RESET" ? "Envoyer une demande de réinitialisation" : "Réinitialiser le mot de passe"}
                            </button>
                            {this.state.form_mode === "CONNEXION" &&
                                <a className="text-frenchBrown p-2 px-4 underline  m-auto  text-center" href={"/"+Page.Login+"?reset=ask"} onClick={() => this.setState({form_mode: "ASK_RESET"})}>
                                    Mot de passe oublié ?
                                </a>
                            } 
                            {this.state.form_mode !== "CONNEXION" &&
                                <a className="text-frenchBrown p-2 px-4 underline  m-auto  text-center" href={"/"+Page.Login} onClick={() => this.setState({form_mode: "CONNEXION"})}>
                                    Se connecter
                                </a>
                            }


                        </div>
                    </div>
                    <div className="pb-2 font-bold">
                        
                        <p className={"p-4 text-center "+this.state.messageColor}>
                            {this.state.isLoading && <img src={loader} alt="loading" className="block ml-auto mr-auto w-10 h-10" />}
                            {this.state.message}
                        </p>
                    </div>
                    <hr className="border-frenchWhite border-1" />
                    <div className="pt-5 p-5">
                        <p className="text-center">
                            Vous n'avez pas encore de compte ? <a href={"/"+Page.Register} className=" text-frenchBrown underline" onClick={this.props.setRegisterPage}>Inscrivez-vous</a>
                        </p>
                    </div>
                </form>
            </div>
        );
    }
}