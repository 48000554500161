// Desc: Profile page
// Path: src/Profile.js

import React from "react";

import WebService from './Service.js';

import Utils from "./Utils.js";

// CSS
import "./App.css";



export default
class Album extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            albums : [],
            currentAlbum : null,
            addAlbumModal : false
        };
    }

    componentDidMount() {
        WebService.albums().then(albums => {
            albums.json().then(albums => {

                // get url parameter ID
                let urlParams = new URLSearchParams(window.location.search);
                let albumId = parseInt(urlParams.get("id"));


                if(albumId) {
                    this.setState({currentAlbum : albums.find(album => album.id === albumId)});
                } else {
                    this.setState({albums: albums});
                }





            });
        });
    }

    render() {
        if(this.state.currentAlbum) {
            return (
                <div className=" block text-frenchWhite">
                    <h1 className="text-center text-frenchBrown font-bold text-3xl p-3">Retour sur {this.state.currentAlbum.name}</h1>
                    <p className="text-center text-gray font-bold text-xl p-3">{this.state.currentAlbum.description}</p>
                    <div className="mb-0 pb-0" id="album">
                        {this.state.currentAlbum.photos.map((photo, index) => {
                            return (
                                <img key={index} className="card card-tall card-wide" src={"/albums/"+photo.link} alt={photo.name} />
                            );
                        })};
                    </div>
                </div>
            );

        } 
        let me = JSON.parse(localStorage.getItem("currentUser"));
        return (
            <div className=" block text-frenchWhite">
                {me != null && ((Utils.isAdmin(me) || 
                (me.hasOwnProperty("associationRole") && me.associationRole === "PHOTOGRAPHER"))) && 
                    <button className=" bg-frenchBrown hover:-translate-y-2 transition duration-500 ease-out-in transform hover:shadow-lg hover:cursor-pointer rounded p-2 m-1" onClick={() => this.setState({addAlbumModal: true})}>Ajouter un album</button>
                }
                {this.state.addAlbumModal &&
                    <div className="fixed z-10 inset-0 overflow-y-auto">
                        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                            <div className="fixed inset-0 transition-opacity" aria-hidden="true">
                                <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
                            </div>
                            <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
                            <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full bg-frenchBlack/75">
                                <div className="border-frenchBrown border-2">
                                    <div className="text-center">
                                        <h3 className="text-lg  bg-frenchBrown text-frenchWhite font-bold" id="modal-title">
                                            Ajouter une actualité
                                        </h3>
                                        <div className="text-frenchWhite">
                                            <form onSubmit={(e) => {

                                                e.preventDefault();
                                                    WebService.createAlbum(e.target.title.value, e.target.date.value, e.target.cover.files[0], e.target.photos.files[0], e.target.credits.value
                                                        ).then(response => {
                                                        if(response.status === 200) {
                                                            response.json().then(data => {
                                                                this.setState({addAlbumModal : false});
                                                                let albums = this.state.albums;
                                                                albums.push(data);
                                                                this.setState({albums : albums});

                                                            });
                                                        }
                                                    });



                                            }} className="flex flex-col items-center justify-center">
                                                <div className="bg-frenchBlack/50 p-5">
                                                    {/* Titre de l'album */}
                                                    <div className="mb-4">
                                                        <label className="block text-frenchWhite text-sm font-bold mb-2 text-left" htmlFor="title">
                                                            Titre de l'album
                                                        </label>
                                                        <input type="text" id="title" placeholder="" className="shadow appearance-none border rounded w-full py-2 px-3 text-frenchBlack leading-tight focus:outline-none focus:shadow-outline" />
                                                    </div>
                                                    {/* Date de l'album (par défaut jourd'hui) */}
                                                    <div className="mb-4">
                                                        <label className="block text-frenchWhite text-sm font-bold mb-2 text-left" htmlFor="date">
                                                            Date de l'album
                                                        </label>
                                                        <input 
                                                            defaultValue={new Date().toISOString().split('T')[0]}type="date" id="date" placeholder="" className="shadow appearance-none border rounded w-full py-2 px-3 text-frenchBlack leading-tight focus:outline-none focus:shadow-outline" />
                                                    </div>
                                                    {/* Photo couverture (with a preview)*/}
                                                    <div className="mb-4">
                                                        <label className="block text-frenchWhite text-sm font-bold mb-2 text-left" htmlFor="cover">
                                                            Photo de couverture
                                                        </label>
                                                        <input 
                                                            onChange={(e) => {
                                                                let reader = new FileReader();
                                                                reader.onload = (e) => {
                                                                    document.getElementById('coverPreview').src = e.target.result;
                                                                }
                                                                reader.readAsDataURL(e.target.files[0]);
                                                            }}
                                                            type="file" id="cover" placeholder="" className="shadow appearance-none border rounded w-full py-2 px-3 text-frenchBlack leading-tight focus:outline-none focus:shadow-outline" />
                                                        <img id="coverPreview" className="w-1/2 h-1/2" src="" alt="Prévisualisation de la couverture" />
                                                    </div>
                                                    {/* Archive .zip des photos */}
                                                    <div className="mb-4">
                                                        <label className="block text-frenchWhite text-sm font-bold mb-2 text-left" htmlFor="photos">
                                                            Fichier .zip contenant les photos
                                                        </label>
                                                        <input 
                                                            onChange={(e) => {
                                                                let reader = new FileReader();
                                                                reader.onload = (e) => {
                                                                    document.getElementById('file_name').innerHTML = "Fichier " + e.target.result.split('base64,')[1].substring(0, 20) + "";
                                                                }
                                                                reader.readAsDataURL(e.target.files[0]);
                                                            }}
                                                        type="file" id="photos" placeholder="" className="shadow appearance-none border rounded w-full py-2 px-3 text-frenchBlack leading-tight focus:outline-none focus:shadow-outline" />
                                                        <p id="file_name"></p>
                                                    </div>
                                                    {/* Crédits photo */}
                                                    <div className="mb-4">
                                                        <label className="block text-frenchWhite text-sm font-bold mb-2 text-left" htmlFor="credits">
                                                            Description
                                                        </label>
                                                        <input type="text" id="credits" defaultValue="© Crédits : <A remplir>" className="shadow appearance-none border rounded w-full py-2 px-3 text-frenchBlack leading-tight focus:outline-none focus:shadow-outline" />
                                                    </div>

                                                    {/* Cancel and submit button */}
                                                    <div className="flex justify-center">
                                                        <input type="submit" className=" font-bold text-center text-xl text-frenchWhite p-3 bg-frenchBrown hover:-translate-y-2 transition duration-500 ease-out-in transform hover:shadow-lg hover:cursor-pointer rounded  m-1" value="Mettre en ligne"></input>
                                                        <button onClick={ () => {this.setState({addAlbumModal : false})}} type="button" className="bg-frenchBrown hover:-translate-y-2 transition duration-500 ease-out-in transform hover:shadow-lg hover:cursor-pointer rounded  m-1">                                                            
                                                            <div className="flex flex-col items-center ">
                                                                <p className="font-bold text-center text-xl text-frenchWhite p-3">Annuler</p>
                                                            </div>
                                                        </button>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>         
                                </div>
                            </div>
                        </div>
                    </div>                
                }
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                    {this.state.albums.sort((a, b) => { return new Date(b.date) - new Date(a.date) }).map((album, index) => {
                        return (
                            // On hover, translate y
                            <a href={"/album?id="+album.id} 
                                key={index} className="flex flex-col items-center justify-center  hover:-translate-y-2 transition duration-500 ease-out-in transform hover:shadow-lg hover:cursor-pointer rounded  m-1">
                                <p className="text-center text-frenchBrown font-bold text-xl p-3">{album.name}</p>
                                    {/* Album cover on /album/<img.link> */}
                                    <img className="border-frenchBrown border-2 rounded-md w-4/5 " 
                                    
                                        src={"/albums/"+ (album.mainPhoto != null ? album.mainPhoto.link : "")
                                        } 
                                        alt={album.name} />
                                    {/* date */}
                                <p className="text-center text-gray font-bold text-xl p-3">Mise en ligne : {Utils.formatDate(new Date(album.date))}</p>
                            </a>
                        );
                    })}
                    
                </div>

            </div>
        );
    }


}