// Desc: Profile page
// Path: src/Profile.js

import React from "react";

import WebService from './Service.js';

import defaultProfileImage from "./images/guest.jpg";

import Utils from "./Utils.js";

import loader from "./images/loader.gif";


function formatAssociationRole(role) {
    switch(role) {
        case "PRESIDENT":
            return "Président";
        case "TREASURER":
            return "Trésorier";
        case "SECRETARY":
            return "Secrétaire";
        case "DESK":
            return "Bureau";
        case "MEMBER":
            return "Membre";
        case "PHOTOGRAPHER":
            return "Photographe";
        case "NONE":
            return "Aucun";
        default:
            return "Aucun";
    }
}

function formatPhone(phone) {
    // with spaces
    return phone.replace(/(\d{2})(\d{2})(\d{2})(\d{2})(\d{2})/, "$1 $2 $3 $4 $5");
}

export default
class Profile extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            user : null,
            isMe : false,
            modifyInformationsModal : false,
            regions : [],
            walletHistory : [],
            feeHistory : [],
            users : [],
            profilePicture : defaultProfileImage,
            addMemberLoading : false,
            walletAddLoading : false
        };
    }

    componentDidMount() {
        let urlParams = new URLSearchParams(window.location.search);
        let userId = parseInt(urlParams.get("id"));

        let me =  JSON.parse(localStorage.getItem("currentUser"));

        if(userId) {
            WebService.user(userId).then((response) => {
                if (response.status === 200) {
                    response.json().then((data) => {
                        let isMe = false;
                        if(me) {
                            isMe = data.id === me.id;
                        }
                        
                        this.setState({user: isMe ? me : data, isMe : isMe});

              
                        setTimeout(async () => {
                            this.setState({profilePicture : await Utils.profilePicture(data)});
                        }, 0);

                        if(Utils.isAdmin(me) || (me && data.id === me.id)) {
                            WebService.walletHistory(userId).then((response) => {
                                if (response.status === 200) {
                                    response.json().then((data) => {
                                        this.setState({walletHistory: data});
                                    });
                                }
                            });

                            WebService.feeHistory(userId).then((response) => {
                                if (response.status === 200) {
                                    response.json().then((data) => {
                                        data = data.map((fee) => {
                                            fee.paid = true;
                                            return fee;
                                        });

                                        let currentYear = new Date().getFullYear();
                                        let nextYear = currentYear + 1;
                                        currentYear = currentYear.toString();
                                        nextYear = nextYear.toString();
                                        if(!data.find((fee) => fee.year === currentYear)) {
                                            data.push({year : currentYear, paid : false});
                                        }
                                        if(!data.find((fee) => fee.year === nextYear)) {
                                            data.push({year : nextYear, paid : false});
                                        }
                                        this.setState({feeHistory: data});
                                    });
                                }
                            });
                        }

                        if(isMe || Utils.isAdmin(me)) {
                            WebService.regions().then((response) => {
                                if (response.status === 200) {
                                    response.json().then((data) => {
                                        data = data.map((region) => region.region);
                                        this.setState({regions: data});
                                    });
                                }
                            });

                        }
                    });
                }
            });
        } else {
            WebService.users().then((response) => {
                if (response.status === 200) {
                    response.json().then((data) => {
                        this.setState({users: data});


                        WebService.wallets().then((response) => {
                            if (response.status === 200) {
                                response.json().then((data) => {
                                    let usersWithWallets = this.state.users.map((user) => {
                                        let wallet = data.filter((wallet) => wallet.ownerId === user.id).reduce((total, wallet) => total + wallet.amount, 0)
                                        user.wallet = wallet;
                                        return user;
                                    }); 
                                    this.setState({users: usersWithWallets});
                                });
                            }
                        });

                        WebService.fees().then((response) => {
                            if (response.status === 200) {
                                response.json().then((data) => {
                                    let currentYear = new Date().getFullYear().toString();

                                    let usersWithFees = this.state.users.map((user) => {
                                        let fee = data.find((fee) => fee.ownerId === user.id && fee.year === currentYear);
                                        user.fee = fee ? true : false;

                                        return user;
                                    });
                                    this.setState({users: usersWithFees});
                                });
                            }
                        });

                    });
                }
            });
        
        }

    }

    render() {
        // ?id=1 from URL parameter
        let user = this.state.user;
        let me =  JSON.parse(localStorage.getItem("currentUser"));
        // solde réel
        let solde_reel = this.state.walletHistory.filter(w => w.validated).reduce((total, wallet) => total + wallet.amount, 0);
        // solde corrigé
        let solde_corrigé = this.state.walletHistory.reduce((total, wallet) => total + wallet.amount, 0);
        // solde en cours
        let solde_cours = this.state.walletHistory.filter(w => !w.validated).reduce((total, wallet) => total + wallet.amount, 0);
        // arrondir à 2 chiffres après la virgule
        solde_reel = Math.round(solde_reel * 100) / 100;
        solde_corrigé = Math.round(solde_corrigé * 100) / 100;
        solde_cours = Math.round(solde_cours * 100) / 100;
        
        if(user) {
            return (
                
                <div className="bg-frenchBlue w-full h-full">
                    {user &&
                    <div className="font-bold text-frenchWhite bg-frenchBlack/50 border-solid border-t-2 border-b-2 ml-4 mr-4 border-frenchBrown" >
                        <h2 className="text-frenchWhite  text-2xl text-center p-3">
                            {user.lastName.toUpperCase()} {user.firstName} <span className="text-frenchBrown">{user.nickname && "(" + user.nickname + ")"}</span>
                        </h2>
                        {(this.state.isMe || Utils.isAdmin(me)) &&
                        <div>
                            <img className=" block ml-auto mr-auto rounded-full w-1/4 h-1/4
                            " src={this.state.profilePicture} alt="profile" />
                            <div className="flex flex-col items-center">

                            <input type="file" id="file" name="file" accept="image/*" className="hidden" 
                                onChange={(event) => {
                                    WebService.setProfilePicture(event.target.files[0]).then(async (response) => {
                                       if (response.status === 200 || response.status === 201) {
                                             this.setState({profilePicture: await Utils.profilePicture(user)});
                                       } 
                                    }); 
                                }}/> 
                            <label htmlFor="file" className="bg-frenchBrown hover:-translate-y-2 transition duration-500 ease-out-in transform hover:shadow-lg hover:cursor-pointer rounded p-2 mt-2">✎ Modifier image</label>   
                                
                            </div>
                        </div>

                        } 
                        {(!this.state.isMe && !Utils.isAdmin(me)) &&
                        <div>
                            <img className="block ml-auto mr-auto rounded-full  w-1/4 h-1/4" src={this.state.profilePicture} alt="profile" />
                        </div>
                        }

                        
                        <p className="text-frenchWhite text-center p-3">
                            <span className="text-frenchBrown ">Membre depuis :</span> {new Date(user.creationDate).toLocaleDateString()}
                        </p>
                        {(this.state.isMe || Utils.isAdmin(me)) && 
                        <div>
                            <button type="button" 
                                    onClick={() => {this.setState({modifyInformationsModal : true})}}
                                    className="bg-frenchBrown hover:-translate-y-2 transition duration-500 ease-out-in transform hover:shadow-lg hover:cursor-pointer rounded">
                                <div className="flex flex-col items-center ">
                                    <h3 className="font-bold text-center text-xl text-frenchWhite p-3 ">
                                        {this.state.isMe ? "Modifier mes informations" : "Modifier les informations de " + user.firstName}
                                    </h3>
                                </div>
                            </button>
                            {/* {(this.state.isMe && me.emailConfirmed === false) && */}
                                 {/* <p>Email non-confirmé</p> */}
                            {/* } */}
                            {this.state.modifyInformationsModal &&
                            <div className="fixed z-10 inset-0 overflow-y-auto">
                                <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                                    <div className="fixed inset-0 transition-opacity" aria-hidden="true">
                                        <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
                                    </div>
                                    <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
                                    <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full bg-frenchBlack/75">
                                        <div className="border-frenchBrown border-2">
                                            <div className="text-center">
                                                <h3 className="text-lg  bg-frenchBrown text-frenchWhite font-bold" id="modal-title">
                                                    {this.state.isMe ? "Modifier mes informations" : "Modifier les informations de " + user.firstName}
                                                </h3>
                                                <div className="text-frenchWhite">
                                                    {/* WebService.updateUser(id, firstName, lastName, nickname, phone, company, rank, regiment, displayContacts, picturePath, region) { */}

                                                    <form
                                                        onSubmit={(event) => {
                                                            event.preventDefault();
                                                            let id = user.id;
                                                            let firstName = document.getElementById("firstname").value;
                                                            let lastName = document.getElementById("lastname").value;
                                                            let nickname = document.getElementById("nickname").value;
                                                            let phone = document.getElementById("phone").value;
                                                            let company = document.getElementById("company").value;
                                                            let rank = null
                                                            let role = null
                                                            if(Utils.isAdmin(me)) {
                                                                rank = document.getElementById("rank").value; // ADMIN
                                                                role = document.getElementById("role").value; // ADMIN
                                                            }
                                                            let regiment = document.getElementById("regiment").value;
                                                            let displayContacts = document.getElementById("displayContacts").value === "true";
                                                            let region = document.getElementById("region").value;
                                                            let picturePath = user.picturePath;
                                                            WebService.updateUser(id, firstName, lastName, nickname, phone, company, rank, regiment, displayContacts, picturePath, region, role).then((response) => {
                                                                if (response.status === 200) {
                                                                     response.json().then((data) => {
                                                                         this.setState({user: data, modifyInformationsModal : false});
                                                                    });
                                                                }
                                                            });
                                                        }}
                                                    >
                                                        <div className="bg-frenchBlack/50 p-5">
                                                            {/* Nom */}
                                                            <div className="mb-4">
                                                                <label className="block text-frenchWhite text-sm font-bold mb-2 text-left" htmlFor="lastname">
                                                                    Nom de famille
                                                                </label>
                                                                <input defaultValue={user.lastName ? user.lastName : ""} type="text" id="lastname" placeholder="" className="shadow appearance-none border rounded w-full py-2 px-3 text-frenchBlack leading-tight focus:outline-none focus:shadow-outline" />
                                                            </div>
                                                            {/* Prénom */}
                                                            <div className="mb-4">
                                                                <label className="block text-frenchWhite text-sm font-bold mb-2 text-left" htmlFor="firstname">
                                                                    Prénom
                                                                </label>
                                                                <input defaultValue={user.firstName ? user.firstName : ""} type="text" id="firstname" placeholder="" className="shadow appearance-none border rounded w-full py-2 px-3 text-frenchBlack leading-tight focus:outline-none focus:shadow-outline" />
                                                            </div>
                                                            {/* Surnmon */}
                                                            <div className="mb-4">
                                                                <label className="block text-frenchWhite text-sm font-bold mb-2 text-left" htmlFor="nickname">
                                                                    Surnom
                                                                </label>
                                                                <input defaultValue={user.nickname ? user.nickname : ""} type="text" id="nickname" placeholder="" className="shadow appearance-none border rounded w-full py-2 px-3 text-frenchBlack leading-tight focus:outline-none focus:shadow-outline" />
                                                            </div>
                                                            {/* Téléphone */}
                                                            <div className="mb-4">
                                                                <label className="block text-frenchWhite text-sm font-bold mb-2 text-left" htmlFor="phone">
                                                                    Téléphone
                                                                </label>
                                                                <input defaultValue={user.phone ? user.phone : ""} type="tel" id="phone" placeholder="" className="shadow appearance-none border rounded w-full py-2 px-3 text-frenchBlack leading-tight focus:outline-none focus:shadow-outline" />
                                                            </div>
                                                            {/* Compagnie (Grenadier, Fusillier, Voltigeur, Civil, Vivandière)*/}
                                                            <div className="mb-4">
                                                                <label className="block text-frenchWhite text-sm font-bold mb-2 text-left" htmlFor="company">
                                                                    Compagnie
                                                                </label>
                                                                <select defaultValue={user.company ? user.company : ""} id="company" className="shadow appearance-none border rounded w-full py-2 px-3 text-frenchBlack leading-tight focus:outline-none focus:shadow-outline">
                                                                    <option value="Fusillier">Fusillier</option>
                                                                    <option value="Voltigeur">Voltigeur</option>
                                                                    <option value="Grenadier">Grenadier</option>
                                                                    <option value="Vivandière">Vivandière</option>
                                                                    <option value="Civil">Civil</option>
                                                                </select>
                                                            </div>
                                                            {/* Régiment (14e, 108e, 74e, 45e, Aucun, Autre) */}
                                                            <div className="mb-4">
                                                                <label className="block text-frenchWhite text-sm font-bold mb-2 text-left" htmlFor="regiment">
                                                                    Régiment
                                                                </label>
                                                                <select defaultValue={user.regiment ? user.regiment : ""} id="regiment" className="shadow appearance-none border rounded w-full py-2 px-3 text-frenchBlack leading-tight focus:outline-none focus:shadow-outline">
                                                                    <option value="108e">108e</option>
                                                                    <option value="14e">14e</option>
                                                                    <option value="74e">74e</option>
                                                                    <option value="45e">45e</option>
                                                                    <option value="Aucun">Aucun</option>
                                                                    <option value="Autre">Autre</option>
                                                                </select>
                                                            </div>
                                                            {/* displayContacts */}
                                                            <div className="mb-4">
                                                                <label className="block text-frenchWhite text-sm font-bold mb-2 text-left" htmlFor="displayContacts">
                                                                    Afficher mes coordonées
                                                                </label>
                                                                <select defaultValue={user.displayContacts ? "true" : "false"} id="displayContacts" className="shadow appearance-none border rounded w-full py-2 px-3 text-frenchBlack leading-tight focus:outline-none focus:shadow-outline">
                                                                    <option value="true">Oui</option>
                                                                    <option value="false">Non</option>
                                                                </select>
                                                            </div>
                                                            <div className="mb-4">
                                                                <label className="block text-frenchWhite text-sm font-bold mb-2 text-left" htmlFor="region">
                                                                    Région
                                                                </label>
                                                                <select defaultValue={(user.region && user.region.region) ? user.region.region : ""} id="region" className="shadow appearance-none border rounded w-full py-2 px-3 text-frenchBlack leading-tight focus:outline-none focus:shadow-outline">
                                                                    {this.state.regions.map((region,i ) => <option key={i} value={region}>{region}</option>)}
                                                                </select>
                                                            </div>
                                                            {Utils.isAdmin(me) &&
                                                            <div>
                                                            {/* Rank (Soldat, Caporal, Sergent, Lieutenant, Caporal-Fourrier, Aucun) */}
                                                                <div className="mb-4">
                                                                    <label className="block text-frenchWhite text-sm font-bold mb-2 text-left" htmlFor="rank">
                                                                        Rang
                                                                    </label>
                                                                    
                                                                    <select defaultValue={user.rank ? user.rank : ""} id="rank" className="shadow appearance-none border rounded w-full py-2 px-3 text-frenchBlack leading-tight focus:outline-none focus:shadow-outline">
                                                                        <option value="Soldat">Soldat</option>
                                                                        <option value="Caporal">Caporal</option>
                                                                        <option value="Sergent">Sergent</option>
                                                                        <option value="Lieutenant">Lieutenant</option>
                                                                        <option value="Caporal-Fourrier">Caporal-Fourrier</option>
                                                                        <option value="Aucun">Aucun</option>
                                                                    </select>
                                                                </div>

                                                                {/* Role PRESIDENT, TREASURER, SECRETARY, DESK, MEMBER, PHOTOGRAPHER, NONE */}
                                                                <div className="mb-4">
                                                                    <label className="block text-frenchWhite text-sm font-bold mb-2 text-left" htmlFor="role">
                                                                        Rôle associatif
                                                                    </label>
                                                                    <select defaultValue={user.associationRole ? user.associationRole : ""} id="role" className="shadow appearance-none border rounded w-full py-2 px-3 text-frenchBlack leading-tight focus:outline-none focus:shadow-outline">
                                                                        <option value="MEMBER">Membre</option>
                                                                        <option value="DESK">Bureau</option>
                                                                        <option value="PHOTOGRAPHER">Photographe</option>
                                                                        <option value="PRESIDENT">Président</option>
                                                                        <option value="TREASURER">Trésorier</option>
                                                                        <option value="SECRETARY">Secrétaire</option>
                                                                        <option value="NONE">Aucun</option>
                                                                    </select>
                                                                </div>
                                                            </div>
                                                            }
                                                            {/* Cancel and submit button */}
                                                            <div className="flex justify-center">
                                                                <input type="submit" className=" font-bold text-center text-xl text-frenchWhite p-3 bg-frenchBrown hover:-translate-y-2 transition duration-500 ease-out-in transform hover:shadow-lg hover:cursor-pointer rounded  m-1" value={this.props.validateName}></input>
                                                                <button onClick={ () => {this.setState({modifyInformationsModal : false})}} type="button" className="bg-frenchBrown hover:-translate-y-2 transition duration-500 ease-out-in transform hover:shadow-lg hover:cursor-pointer rounded  m-1">
                                                                    <div className="flex flex-col items-center ">
                                                                        <p className="font-bold text-center text-xl text-frenchWhite p-3">Annuler</p>
                                                                    </div>
                                                                </button>
                                                            </div>
                                                            
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>         
                                        </div>
                                    </div>
                                </div>
                            </div>
                            }
                        </div>
                        }
                        {(this.state.isMe || (user.displayContacts && Utils.isMember(me))) &&
                        <div>
                            <hr className="border-frenchBrown border-2 mt-4 ml-4 mr-4" />
                            <h2 className="text-frenchWhite  text-2xl text-center p-2">
                                Contact
                            </h2>
                            {user.phone &&
                                <p className="text-frenchWhite text-center p-2">
                                    <span className="text-frenchBrown">Téléphone : </span>{formatPhone(user.phone)}
                                </p>
                            }
                            {user.email &&
                                <p className="text-frenchWhite text-center p-2">
                                    <span className="text-frenchBrown">E-mail : </span>{user.email}
                                </p>
                            }
                        </div>
                        }

                        <hr className="border-frenchBrown border-2 mt-4 ml-4 mr-4" />
                        {(Utils.isMember(me)) &&
                        <div>
                            <h2 className="text-frenchWhite  text-2xl text-center p-2">
                            Informations
                            </h2>
                            {/* Région */}
                            {user.region &&
                                <p className="text-frenchWhite text-center p-2">
                                    <span className="text-frenchBrown">Région : </span>{user.region.region}
                                </p>
                            }
                            {/* Rank */}
                            {user.rank &&
                                <p className="text-frenchWhite text-center p-2">
                                    <span className="text-frenchBrown">Rang : </span>{user.rank}
                                </p>
                            }
                            {/* Compagnie */}
                            {user.company &&
                                <p className="text-frenchWhite text-center p-2">
                                    <span className="text-frenchBrown">Compagnie : </span>{user.company}
                                </p>
                            }
                            {/* Régiment */}
                            {user.regiment &&
                                <p className="text-frenchWhite text-center p-2">
                                    <span className="text-frenchBrown">Régiment : </span>{user.regiment}
                                </p>
                            }
                            {/* Role associatif */}
                            {user.associationRole &&
                                <p className="text-frenchWhite text-center p-2">
                                    <span className="text-frenchBrown">Rôle associatif : </span>{formatAssociationRole(user.associationRole)}
                                </p>
                            }
                        </div>
                        }
                    </div>
                    }

                    {(Utils.isAdmin(me) || (this.state.isMe && Utils.isMember(user) && Utils.isOnlyGuest(user))) &&
                        <div className="font-bold text-frenchWhite bg-frenchBlack/50 border-solid border-t-2 border-b-2  border-frenchBrown ml-4 mt-4 mr-4" >

                            <h2 className="text-frenchWhite  text-2xl text-center p-2">
                                Cotisations
                            </h2>
                            {/* If all fees are paid, dont display the form */}

                            {(Utils.isAdmin(me) && this.state.feeHistory.filter((fee) => fee.paid === false).length !== 0 )&&
                                // Cotisation form to select year 
                                
                                <form
                                    onSubmit={
                                        (event) => {
                                            event.preventDefault();
                                            let id = user.id;
                                            let year = document.getElementById("year").value;
                                            WebService.payFee(id, year).then((response) => {
                                                if (response.status === 200) {
                                                    // replace the element of the array with year equals to year and set paid to true
                                                    let feeHistory = this.state.feeHistory;
                                                    feeHistory = feeHistory.map((fee) => {
                                                        if(fee.year === year) {
                                                            fee.paid = true;
                                                        }
                                                        return fee;
                                                    });
                                                    this.setState({feeHistory: feeHistory});

                                                }
                                            });
                                        }
                                    }>
                                    <select id="year" className="shadow appearance-none border rounded w-2/12 py-2 px-3 text-frenchBlack leading-tight focus:outline-none focus:shadow-outline">
                                        
                                        {/*  If fee of the cyrrebt year is not paied */}
                                        {(this.state.feeHistory.length !== 0 && this.state.feeHistory.find((fee) => fee.year === (new Date().getFullYear()).toString()).paid === false) &&
                                            <option value={(new Date().getFullYear()).toString()}>{(new Date().getFullYear()).toString()}</option>
                                        }
                                        {/*  If fee of the next year is not paied */}
                                        {(this.state.feeHistory.length !== 0 && this.state.feeHistory.find((fee) => fee.year === ((new Date().getFullYear())+1).toString()).paid === false) &&
                                            <option value={((new Date().getFullYear())+1).toString()}>{((new Date().getFullYear())+1).toString()}</option>
                                        }
                                    </select>
                                    <input type="submit" className=" font-bold text-center text-s text-frenchWhite p-2 bg-frenchBrown hover:-translate-y-2 transition duration-500 ease-out-in transform hover:shadow-lg hover:cursor-pointer rounded m-1" value="Valider la cotisation"></input>
                                </form>                            
                            }

                            <table className="table-auto mx-auto w-3/4">
                                <thead className="border-frenchBrown border-b-2">
                                    <tr>
                                        <th className="text-frenchWhite text-center p-2">Année</th>
                                        <th className="text-frenchWhite text-center p-2">Status</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.feeHistory
                                        .sort((a, b) => new Date(b.year) - new Date(a.year))
                                        .map((fee) => {
                                        return (
                                            <tr className="border-frenchBrown" key={fee.year}>
                                                <td className="text-frenchWhite  p-2 break-words text-left">{fee.year}</td>
                                                <td className={"text-center p-2 " + (fee.paid ? " text-goodColor" : " text-badColor")}>{fee.paid ? "Payé" : "Non payé"}</td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>
                            
                                    


                            <hr className="border-frenchBrown border-2 mt-4 ml-4 mr-4" />

                            <h2 className="text-frenchWhite  text-2xl text-center p-2 underline">Cagnotte personnelle</h2>

                            <h3 className="text-frenchWhite  text-xl text-center p-2">
                                Solde corrigé : <span className={"text-center p-2 " + (solde_corrigé === 0 ? "text-neutralColor" :solde_corrigé > 0 ? " text-goodColor" : " text-badColor")}>{solde_corrigé} €</span>
                            </h3>
                            <h3 className="text-frenchWhite  text-l text-center p-2">
                                Solde réel : <span className={"text-center p-2 " + (solde_reel === 0 ? "text-neutralColor" :solde_reel > 0 ? " text-goodColor" : " text-badColor")}>{solde_reel} €</span>
                            </h3>
                            <h3 className="text-frenchWhite  text-l text-center p-2">
                                Opération en cours : <span className={"text-center p-2 " + (solde_cours === 0 ? "text-neutralColor" : solde_cours > 0 ? " text-goodColor" : " text-badColor")}>{solde_cours} €</span>
                            </h3>

                            {Utils.isAdmin(me) &&
                                <form
                                    onSubmit={(event) => {
                                        event.preventDefault();
                                        this.setState({walletAddLoading : true});
                                        let id = user.id;
                                        let amount = parseFloat(document.getElementById("amount").value);
                                        let comment = document.getElementById("comment").value;
                                        let file = document.getElementById("file_wallet").files[0];
                                        
                                        WebService.addWallet(id, amount, comment, file).then((response) => {
                                            if (response.status === 200) {
                                                response.json().then((data) => {
                                                    let walletHistory = this.state.walletHistory;
                                                    walletHistory.push(data);
                                                    this.setState({walletHistory: walletHistory});
                                                });
                                            }
                                            this.setState({walletAddLoading : false});
                                        });
                                    }}
                                >
                                    {/* addWallet (form with number and comment) */}
                                        <input type="text" id="comment" placeholder="Commentaire" className="shadow appearance-none border rounded w-4/12 py-2 px-3 text-frenchBlack leading-tight focus:outline-none focus:shadow-outline" />
                                        <input type="number" step="any" id="amount" placeholder="Montant" className="shadow appearance-none border rounded w-1/12 py-2 px-3 text-frenchBlack leading-tight focus:outline-none focus:shadow-outline" />
                                        <br></br>
                                        <input type="file" id="file_wallet" name="file_wallet"></input>
                                        <input type="submit" className=" font-bold text-center text-l text-frenchWhite p-3 bg-frenchBrown hover:-translate-y-2 transition duration-500 ease-out-in transform hover:shadow-lg hover:cursor-pointer rounded m-1" value="Ajouter"></input>
                                        {this.state.walletAddLoading &&
                                            <div className="flex flex-col items-center">
                                                <img src={loader} alt="loading" className="w-10 h-10"/>
                                                <p className="text-gray text-center p-3">Mis à jour de la cagnotte...</p>
                                            </div>
                                        }
                                </form>
                            }
                            {this.state.walletHistory.length > 0 &&
                            // Centered table/ maximum width
                                <table className="table-auto mx-auto w-3/4">
                                    <thead className="border-frenchBrown border-b-2">
                                        <tr>
                                         <th className="text-frenchWhite text-center p-2">Date</th>
                                            <th className="text-frenchWhite text-center p-2">Libellé</th>
                                            <th className="text-frenchWhite text-center p-2">Montant</th>
                                            <th className="text-frenchWhite text-center p-2">Document</th>
                                            <th className="text-frenchWhite text-center p-2">Statut</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.walletHistory
                                            .sort((a, b) => new Date(b.datetime) - new Date(a.datetime))
                                            .map((wallet) => {
                                            return (
                                                <tr className="border-frenchBrown" key={wallet.id}>
                                                    <td className="text-frenchWhite text-center p-2">{new Date(wallet.datetime).toLocaleDateString('fr-FR', {year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute:'numeric'})}</td>
                                                    <td className="text-frenchWhite  p-2 break-words text-left">{wallet.description}</td>
                                                    <td className={"text-center p-2 " + (wallet.amount > 0 ? " text-goodColor" : " text-badColor")}>{wallet.amount} €</td>
                                                    {wallet.document &&
                                                        <td className="text-frenchBrown text-center p-2 ">
                                                            <a href={wallet.document} target="_blank" rel="noreferrer">Télécharger</a>
                                                        </td>
                                                    }
                                                    {!wallet.document &&
                                                        <td className="text-gray text-center p-2">
                                                            Aucun
                                                        </td>
                                                    }
                                                      <td>
                                                        {wallet.validated &&
                                                            <p className="bg-goodColor rounded text-center p-1">Validé</p>
                                                        }
                                                        {!wallet.validated &&
                                                            <p onClick={() => {
                                                                // is admin
                                                                if(!Utils.isAdmin(me)) {
                                                                    return;
                                                                }
                                                                if(window.confirm("Voulez-vous vraiment valider cette opération ?")) {
                                                                    WebService.validateWalletOperation(wallet.id).then((response) => {
                                                                        if (response.status === 200) {
                                                                            let walletHistoryCopy = this.state.walletHistory;
                                                                            walletHistoryCopy = walletHistoryCopy.map((w1) => {
                                                                                if(w1.id === wallet.id) {
                                                                                    w1.validated = true;
                                                                                }
                                                                                return w1;
                                                                            });

                                                                            
                                                                            this.setState({walletHistory: walletHistoryCopy});
                                                                        }
                                                                    });
                                                                }
                                                            }} 
                                                            className="bg-waitingColor rounded text-center p-1 hover:cursor-pointer">Opération en cours</p>
                                                        }
                                                    </td>
                                                </tr>
                                                
                                            )
                                        })}
                                    </tbody>
                                </table>
                            }
                            {this.state.walletHistory.length === 0 &&
                                <p className="text-frenchWhite text-center p-2 mb-3 bg-neutralColor inline-block rounded">
                                    Aucun historique de cagnotte
                                </p>
                            }
                        </div> 
                        }
                        <br></br>

                </div>
            )
        }

        return (
            
            <div className="bg-frenchBlue w-full h-full">
                <br></br>
                <div className="font-bold text-frenchWhite bg-frenchBlack/50 border-solid border-t-2 border-b-2 ml-4 mr-4 mb-5 border-frenchBrown" >
                    
                    {/* {(Utils.isAdmin(me) && this.state.users.filter((user) => !Utils.isMember(user)).length !== 0) &&
                    <div>
                        <hr className="border-frenchBrown border-2 mt-4 ml-4 mr-4 mt-14" />
                        <h2 className="text-frenchWhite text-xl text-left p-3 font-bold">
                            Non-validés
                        </h2>
                        <form className="text-left p-3 pl-6" 
                            onSubmit={(event) => {
                                event.preventDefault();
                                let id = parseInt(document.getElementById("user").value);
                                this.setState({addMemberLoading : true});
                                WebService.setRole(id, "MEMBER").then((response) => {
                                    if (response.status === 200) {
                                        let users = this.state.users;
                                        users = users.map((user) => {
                                            if(user.id === id) {
                                                user.associationRole = "MEMBER";
                                            }
                                            return user;
                                        });
                                        this.setState({users: users, addMemberLoading : false});
                                        this.setState({users: users});
                                    }
                                });
                            }}
                        >
                            <select defaultValue={""} id="user" className="shadow appearance-none border rounded w-2/12 py-2 px-3 text-frenchBlack leading-tight focus:outline-none focus:shadow-outline">
                                {this.state.users.filter((user) => !Utils.isMember(user)).map((user) => <option key={user.id} value={user.id}>{user.lastName.toUpperCase()} {user.firstName}</option>)}
                            </select>
                            <input type="submit" className=" font-bold text-center text-xl text-frenchWhite p-3 bg-frenchBrown hover:-translate-y-2 transition duration-500 ease-out-in transform hover:shadow-lg hover:cursor-pointer rounded m-1" value="Faire membre"></input>
                            {this.state.addMemberLoading &&
                            <div className="flex flex-col items-center">
                                <img src={loader} alt="loading" className="w-10 h-10"/>
                                <p className="text-gray text-center p-3">Envoi d'une notification à l'utilisateur...</p>
                            </div>
                            }
                        </form>
                    </div>
                    } */}

                    <hr className="border-frenchBrown border-2 mt-4 ml-4 mr-4 mt-14" />
                    <h2 className="text-frenchWhite text-xl text-left p-3 font-bold">
                        Membres
                    </h2>
                    {/* Align center */}
                    <table className="ml-5 text-left mx-auto w-11/12 whitespace-pre-wrap">
                        <thead className="border-frenchBrown border-b-2">
                            <tr>
                                <th className="text-frenchWhite p-2">Nom</th>
                                <th className="text-frenchWhite text-center p-2">Rôle</th>
                                <th className="text-frenchWhite text-center p-2">Roleplay</th>
                                {/* {Utils.isAdmin(me) &&
                                <th className="text-frenchWhite text-center p-2">Contact</th>
                                } */}
                                <th className="text-frenchWhite  text-center p-2">Région</th>
                                <th>
                                {/* {Utils.isAdmin(me) &&
                                    <span className="text-frenchWhite p-2">Cagnotte</span>
                                } */}
                                </th>
                                <th>
                                {/* {Utils.isAdmin(me) &&
                                    <span className="text-frenchWhite p-2">Cotisation {
                                        new Date().getFullYear()}</span>
                                } */}
                                </th>
                                
                            </tr>
                        </thead>
                        <tbody className="border-frenchBrown border-b-2 ">
                            {this.state.users
                                .sort((a, b) => a.lastName.localeCompare(b.lastName))
                                // .filter((user) => Utils.isMember(user) && !Utils.isAdmin(user))
                                // sort by Utils.roleImportance(user)
                                // remove NONE role
                                .filter((user) => user.associationRole !== "NONE")
                                .filter((user) => user.associationRole !== "IGNORE")
                                .sort((a, b) => Utils.roleImportance(a) - Utils.roleImportance(b))
                                .map((user) => {
                                return (
                                    <tr className="border-frenchBrown text-l font-normal" key={user.id}>
                                        <td className="text-frenchWhite  p-1 pl-6 text-left">
                                            <a className="hover:text-frenchBrown hover:underline transition duration-300"href={"/user?id=" + user.id}>{user.lastName.toUpperCase()} {user.firstName}</a> {user.nickname && "(" + user.nickname + ")"}                      
                                        </td>
                                        <td className="text-frenchWhite  p-1 pl-6 text-left">
                                            {/* {Utils.isAdmin(me) &&
                                                <div>
                                                    <select 
                                                        onChange={(event) => {
                                                            let id = user.id;
                                                            let role = event.target.value;
                                                            WebService.setRole(user.id, role).then((response) => {
                                                                if (response.status === 200) {
                                                                        let users = this.state.users;
                                                                        users = users.map((user) => {
                                                                            if(user.id === id) {
                                                                                user.associationRole = role;
                                                                            }
                                                                            return user;
                                                                        });
                                                                        this.setState({users: users});
                                                                }
                                                            });
                                                        }}

                                                    
                                                    defaultValue={user.associationRole ? user.associationRole : ""} id="role" className="shadow appearance-none border rounded w-full py-2 px-3 text-frenchBlack leading-tight focus:outline-none focus:shadow-outline">
                                                        <option value="MEMBER">Membre</option>
                                                        <option value="DESK">Bureau</option>
                                                        <option value="PHOTOGRAPHER">Photographe</option>
                                                        <option value="PRESIDENT">Président</option>
                                                        <option value="TREASURER">Trésorier</option>
                                                        <option value="SECRETARY">Secrétaire</option>
                                                        <option value="NONE">Aucun</option>
                                                    </select>
                                                </div>
                                            } */}
                                            { 
                                                <div>
                                                    {Utils.roleLabels(user)}
                                                </div>
                                            }
                                        </td>
                                        <td className="text-frenchWhite  p-1 pl-6 text-left">
                                            {Utils.roleplayLabel(user)}
                                        </td>
                                        {/* {Utils.isAdmin(me) &&
                                        <td className="text-frenchWhite  p-1 pl-6 text-left">
                                            {(user.phone && user.phone !== "") && formatPhone(user.phone)}
                                            {user.email && <p>{user.email}</p>}
                                        </td>
                                        } */}
                                        <td className="text-frenchWhite  p-1 pl-6 text-left">
                                            {user.region && user.region.region}
                                        </td>
                                        <td>
                                            {/* {Utils.isAdmin(me) &&
                                                <div>
                                                    <span className={"p-1 pl-6 text-left font-bold " + (user.wallet > 0 ? "text-goodColor" : user.wallet < 0 ? "text-badColor" : "text-waitingColor")}>{user.wallet} €</span> 
                                                </div>
                                            } */}
                                        </td>
                                        <td>
                                            {/* {Utils.isAdmin(me) &&
                                                <div>
                                                    <span className={" p-1 pl-6 text-left font-bold " + (user.fee ? " text-goodColor" : " text-badColor")}>{user.fee ? "À jour" : "Impayée"}</span> 
                                                    {!user.fee &&
                                                        
                                                            <button className=" bg-frenchBrown hover:-translate-y-2 transition duration-500 ease-out-in transform hover:shadow-lg hover:cursor-pointer rounded  m-1"
                                                                onClick={() => {
                                                                    let id = user.id;
                                                                    let year = (new Date().getFullYear()).toString();
                                                                    WebService.payFee(id, year).then((response) => {
                                                                        if (response.status === 200) {
                                                                            let users = this.state.users;
                                                                            users = users.map((user) => {
                                                                                if(user.id === id) {
                                                                                    user.fee = true;
                                                                                }
                                                                                return user;
                                                                            });
                                                                            this.setState({users: users});
                                                                        }
                                                                    });
                                                                }}
                                                            >
                                                                Marquer
                                                            </button>
                                                        
                                                    }
                                                </div>
                                            } */}
                                        </td>
                                    </tr>
                                )
                            })}
                            
                            
                        </tbody>
                    </table>


                </div>
            </div>
        )
    }
    
}