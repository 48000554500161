import React from "react";
import "./App.css";
import "./index.css";

import WebService from "./Service.js";


import Utils from "./Utils.js";




export default
class Events extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            creatingOrder : false,
            creatingItemsQuantity : 1
        };
    }

    componentDidMount() {
        //this.getEvents();
    }

    render() {
        let me = Utils.me();

        return (
            <div className="bg-frenchBlue block text-frenchWhite">
                {Utils.isMember(me) &&
                <div>
                    <div className="font-bold text-frenchWhite bg-frenchBlack/50 border-solid border-t-2 border-b-2  border-frenchBrown ml-4 pt-4 mr-4 pt-4">
                        <h2 className="text-frenchBrown underline text-2xl text-center p-2">
                            Bons de commande
                        </h2>
                        <div ></div>
                    </div>
                    <div className="font-bold text-frenchWhite bg-frenchBlack/50 border-solid border-t-2 border-b-2  border-frenchBrown ml-4 pt-4 mr-4 pt-4 m-5">
                        <h2 className="text-frenchBrown text-left underline text-2xl p-5">
                            Créer un bon de commande
                        </h2>
                        <div className="m-auto align-center p-5 justify-center">
                            <form className="text-frenchWhite text-left" onSubmit={(e) => {
                                e.preventDefault();

                                let name = e.target.name.value;
                                let description = e.target.description.value;
                                let expires = e.target.expires.value;
                                let items = [];
                                for(let i = 0; i < this.state.creatingItemsQuantity; i++) {
                                    items.push({
                                        name: e.target["name"+i].value,
                                        description: e.target["description"+i].value,
                                        quantity: parseInt(e.target["quantity"+i].value),
                                        price: parseInt(e.target["price"+i].value),
                                        // picture: e.target["picture"+i].files[0]
                                        picture: null
                                    });
                                }
                                console.log(items);
                                let request = WebService.createOrder(name, description, expires, items);
                                request.then((response) => {
                                    if (response.status === 200) {
                                    
                                    }
                                });

                                

                            }}>
                                <table className="ml-10">
                                    {/* Name */}
                                    <tr>
                                        <td >
                                            <label className="block text-frenchWhite  font-bold  text-left" htmlFor="name">Nom</label>
                                        </td>
                                        <td>
                                            <input className="shadow appearance-none border rounded w-full py-2 px-3 text-frenchBlack leading-tight focus:outline-none focus:shadow-outline" type="text" name="name" id="name" required />
                                        </td>
                                    </tr>
                                    {/* Description */}
                                    <tr>
                                        <td>
                                            <label className="block text-frenchWhite  font-bold  text-left" htmlFor="description">Description</label>
                                        </td>
                                        <td>
                                            <textarea className="shadow appearance-none border rounded w-full py-2 px-3 text-frenchBlack leading-tight focus:outline-none focus:shadow-outline" name="description" id="description" required ></textarea>
                                        </td>
                                    </tr>
                                    {/* Expires date*/}
                                    <tr>
                                        <td>
                                            <label className="block text-frenchWhite  font-bold  text-left" htmlFor="expires">Date d'expiration</label>
                                        </td>
                                        <td>
                                            <input className="shadow appearance-none border rounded w-full py-2 px-3 text-frenchBlack leading-tight focus:outline-none focus:shadow-outline" type="date" name="expires" id="expires" required />
                                        </td>
                                    </tr>
                                    {/* Items */}
                                    <div className="m-auto align-center p-5 justify-center">
                                        <h4 className="text-frenchBrown text-left underline text-xl p-2">
                                            Objets de la commande
                                        </h4>
                                        {Array.from(Array(this.state.creatingItemsQuantity).keys()).map((index) => {
                                            return (
                                                // Center the table
                                                <div className="m-auto mt-5">
                                                    <table key={index} >
                                                        {/* Name */}
                                                        <tr>
                                                            <td>
                                                                <label className="block text-frenchWhite  font-bold  text-left" htmlFor={"name"+index}>Nom</label>
                                                            </td>
                                                            <td>
                                                                <input className="shadow appearance-none border rounded w-full py-2 px-3 text-frenchBlack leading-tight focus:outline-none focus:shadow-outline" type="text" name={"name"+index} id={"name"+index} required />
                                                            </td>
                                                        </tr>
                                                        {/* Description */}
                                                        <tr>
                                                            <td>
                                                                <label className="block text-frenchWhite  font-bold  text-left" htmlFor={"description"+index}>Description</label>
                                                            </td>
                                                            <td>
                                                                <textarea className="shadow appearance-none border rounded w-full py-2 px-3 text-frenchBlack leading-tight focus:outline-none focus:shadow-outline" name={"description"+index} id={"description"+index} required ></textarea>
                                                            </td>
                                                        </tr>
                                                        {/* Quantity */}
                                                        <tr>
                                                            <td>
                                                            <label className="block text-frenchWhite  font-bold  text-left" htmlFor={"quantity"+index}>Quantité maximale </label>
                                                            </td>
                                                            <td>
                                                                <input className="shadow appearance-none border rounded w-full py-2 px-3 text-frenchBlack leading-tight focus:outline-none focus:shadow-outline" type="number" name={"quantity"+index} id={"quantity"+index} required />
                                                            </td>
                                                        </tr>
                                                        {/* Price */}
                                                        <tr>
                                                            <td>
                                                                <label className="block text-frenchWhite  font-bold  text-left" htmlFor={"price"+index}>Prix</label>
                                                            </td>
                                                            <td>
                                                                <input className="shadow appearance-none border rounded w-full py-2 px-3 text-frenchBlack leading-tight focus:outline-none focus:shadow-outline" type="number" name={"price"+index} id={"price"+index} required />
                                                            </td>
                                                        </tr>
                                                        {/* Picture */}
                                                        <tr>
                                                            <td>
                                                                <label className="block text-frenchWhite  font-bold  text-left" htmlFor={"picture"+index}>Image</label>
                                                            </td>
                                                            <td>
                                                                <input className="shadow appearance-none  rounded w-full py-2 px-3 leading-tight
                                                                focus:outline-none focus:shadow-outline" type="file" name={"picture"+index} id={"picture"+index} />
                                                            </td>
                                                        </tr>
                                                    </table>
                                                </div>
                                                
                                            );
                                        })}
                                        {/* Add item */}
                                        <div className="m-auto mt-5 align-center p-5 justify-center">
                                            <button className="bg-frenchBrown rounded p-2 px-4" type="button" onClick={() => this.setState({creatingItemsQuantity: this.state.creatingItemsQuantity+1})}>
                                                Ajouter un objet
                                            </button>
                                            {/* Remove item */}
                                            <button className="bg-frenchBrown rounded p-2 px-4 ml-4" type="button" onClick={() => this.setState({creatingItemsQuantity: this.state.creatingItemsQuantity-1})}>
                                                Retirer un objet
                                            </button>
                                        </div>
                                        {/* Submit */}
                                        <div className="m-auto mt-5 align-center p-5 justify-center">
                                            <button className="bg-frenchBrown rounded p-2 px-4" type="submit">
                                                Créer
                                            </button>
                                        </div>
                                    </div>
                                </table>
                            </form>
                        </div>
                    </div>
                    <div className="font-bold text-frenchWhite bg-frenchBlack/50 border-solid border-t-2 border-b-2  border-frenchBrown ml-4 pt-4 mr-4 pt-4">
                        <h2 className="text-frenchWhite underline text-2xl text-center p-2">
                            Vos commandes en cours
                        </h2>
                        <div ></div>
                    </div>
                </div>
                }
            </div>
        );
        
    }
}