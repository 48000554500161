import CryptoJS from 'crypto-js';

class WebService {
        // API CONSTANTS
    // static API_URL = "http://localhost:8080/api";
    static API_URL = "/api";
    static API_AUTH_URL = "/auth";
    static TOKEN = "ff543a83-e5dd-671e-ddba-04cefba159b3-effbb408-6203-4947-8504-5faaa03b38a9";

    static setToken(token) {
        // Expires in 365 days
        let date = new Date();
        date.setTime(date.getTime() + (365 * 24 * 60 * 60 * 1000));
        document.cookie = "token=" + token + "; expires=" + date.toUTCString() + "; path=/;";
        console.log(document.cookie);
       
    }

    static getToken() {
        let cookies = document.cookie.split(';');
        for (let i = 0; i < cookies.length; i++) {
            let cookie = cookies[i].trim();
            if (cookie.startsWith("token=")) {
                return cookie.substring("token=".length, cookie.length);
            }
        }
        return false;
    }


    static getEventPin() {
        return WebService.fetchAPI("/event/pin", "GET");
    }

    static createEventPin(name, x, y, isOut, type, eventId) {
        return WebService.fetchAPI("/event/pin", "POST", {name: name, x: x, y: y, isOut: isOut, type: type, eventId: eventId});
    }

    static getMemberPin() {
        return WebService.fetchAPI("/member/pin", "GET");
    }

    static createMemberPin(x, y, size) {
        return WebService.fetchAPI("/member/pin", "POST", {x: x, y: y, size:size});
    }



    static fetchAPI(endpoint, method, parameters = false, api = WebService.API_URL, type = 'application/json') {
        let headers = {
            'Content-Type': type,
            'Origin': '/'
        }
        let token = WebService.getToken();
        if (token) {headers['Authorization'] = 'Bearer ' + token;}
        let request  = {method: method, headers: headers}

        if(parameters) {
            if("multipart/form-data" === type) {
                request["body"] = parameters;
            } else {
                request["body"] = JSON.stringify(parameters);
            }
        }

        return fetch(api + endpoint, request);
    }


    static resolveTicket(id) {
        return WebService.fetchAPI("/ticket/" + id + "/validate", "PUT");
    }

    static sendTicket(content, attachment) {
        let formData = new FormData();
        formData.append("content", content);
        formData.append("document", attachment);

        let headers = {
            //  'Content-Type': 'multipart/form-data',
            'Origin': '/'

        }
        let token = WebService.getToken();
        if (token) {headers['Authorization'] = 'Bearer ' + token;}
        let request  = {method: "POST", headers: headers, body: formData}

        return fetch(WebService.API_URL + "/ticket", request);
    }

    static getTickets() {
        return WebService.fetchAPI("/ticket", "GET");
    }

    static setRole(id, role) {
        // /user/role/{id}
        return WebService.fetchAPI("/user/" + id + "/role", "PUT", {role: role});
    }


    static result() {
        return WebService.fetchAPI("/results", "GET");
    }


    static albums() {
        return WebService.fetchAPI("/album", "GET");
    }



    static createAssociationTransaction(amount, description, document) {
        //console.log(amount, description, document);

        // return WebService.fetchAPI("/wallet/association", "POST", {amount : amount, description : description});

        let formData = new FormData();
        formData.append("amount", amount);
        formData.append("description", description);
        formData.append("document", document);

        let headers = {
            //  'Content-Type': 'multipart/form-data',
            'Origin': '/'
        }
        let token = WebService.getToken();
        if (token) {headers['Authorization'] = 'Bearer ' + token;}
        let request  = {method: "POST", headers: headers, body: formData}

        return fetch(WebService.API_URL + "/wallet/association", request);


        
    }

    static validateWalletOperation(id) {
        return WebService.fetchAPI("/wallet/" + id, "PUT");
    }

    static missingFees() {
        return WebService.fetchAPI("/fee/missing", "GET");
    }

    static missingConfirmation() {
        return WebService.fetchAPI("/confirmation/missing", "GET");
    }

    static equipement() {
        return WebService.fetchAPI("/equipment", "GET");
    }

    static deleteEquipment(id) {
        return WebService.fetchAPI("/equipment/" + id, "DELETE");
    }

    static setEquipmentCategory(id, category) {
        return WebService.fetchAPI("/equipment/" + id + "/category", "PUT", {category: category});
    }

    // my equipment
    static myEquipments() {
        return WebService.fetchAPI("/equipment/my", "GET");
    }

    static userEquipments(userId) {
        return WebService.fetchAPI("/equipment/user/" + userId, "GET");
    }

    // Update equipment status
    static updateEquipmentStatus(userid, id, status) {
        return WebService.fetchAPI("/equipment/" + id + "/status", "PUT", {userId: userid, status: status});
    }

    static createEquipment(name, category) {
        return WebService.fetchAPI("/equipment", "POST", {name: name, category: category});
    }

    static association() {
        return WebService.fetchAPI("/wallet/association", "GET");
    }

    /* On spring :
        @PostMapping("/album")
            public ResponseEntity<Void> createAlbum(
                @RequestParam String name,
                @RequestParam Date date,
                @RequestParam("coverData") MultipartFile coverData,
                @RequestParam("archiveData") MultipartFile archiveData
            ) 
    */
    static createAlbum(name, date, coverData, archiveData, credits) {
        let formData = new FormData();
        formData.append("name", name);
        formData.append("date", new Date(date));
        formData.append("coverData", coverData);
        formData.append("archiveData", archiveData);
        formData.append("description", credits);

        let headers = {
            //  'Content-Type': 'multipart/form-data',
            'Origin': '/'
        }
        let token = WebService.getToken();
        if (token) {headers['Authorization'] = 'Bearer ' + token;}
        let request  = {method: "POST", headers: headers, body: formData}

        return fetch(WebService.API_URL + "/album", request);

        // return WebService.fetchAPI("/album", "POST", formData, WebService.API_URL, "multipart/form-data");


    }

    static sendEventMail(eventId) {

        return WebService.fetchAPI("/mail/event/" + eventId, "POST");
    }


    // @PostMapping("/order")
    // public ResponseEntity<Void> createOrder(
    //         @RequestParam String name,
    //         @RequestParam String description,
    //         @RequestParam List<ItemRequest> items,
    //         @RequestParam Date expiresIn
    // ) 
    static createOrder(name, description, expiresIn, items) {
        return WebService.fetchAPI("/order", "POST", {name: name, description:description, items:items, expiresIn:expiresIn});
    }


    // /profile/picture
    static setProfilePicture(pictureData) {
        let formData = new FormData();
        formData.append("file", pictureData);

        let headers = {
            //  'Content-Type': 'multipart/form-data',
            'Origin': '/'
        }
        let token = WebService.getToken();
        if (token) {headers['Authorization'] = 'Bearer ' + token;}
        let request  = {method: "POST", headers: headers, body: formData}

        return fetch(WebService.API_URL + "/profile/picture", request);
    }

    static wallets() {
        return WebService.fetchAPI("/wallet", "GET");
    }

    static fees() {
        return WebService.fetchAPI("/fee", "GET");
    }

    static actualities() {
        return WebService.fetchAPI("/actualities", "GET");
    }

    static addActuality(title, content, type, link, isPublic = true) {
        return WebService.fetchAPI("/actualities", "POST", {title : title, content:content, type:type, link:link, isPublic:isPublic});
    }



    static logout() {
        document.cookie = "token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    }

    static hashPassword(password) {
        return CryptoJS.SHA1(password).toString(CryptoJS.enc.Hex);
    }

    static register(username, password, firstName, lastName, acceptMail=true) {
        password = WebService.hashPassword(password);
        return WebService.fetchAPI("/register", "POST", {login: username.toLowerCase(), password: password, firstName: firstName, lastName: lastName, acceptMail:acceptMail}, WebService.API_AUTH_URL);
    }

    static login(username, password, remember = true) {
        password = WebService.hashPassword(password);
        return WebService.fetchAPI("/login", "POST", {login: username.toLowerCase(), password: password, remember}, WebService.API_AUTH_URL);
    }

    static confirmMail(token) {
        return WebService.fetchAPI("/confirm/" + token, "POST", false, WebService.API_AUTH_URL);
    }

    static askResetPassword(email) {
        return WebService.fetchAPI("/ask", "POST", {login: email}, WebService.API_AUTH_URL);
    }

    static resetPassword(token, password) {
        password = WebService.hashPassword(password);
        return WebService.fetchAPI("/reset/" + token, "POST", {password: password}, WebService.API_AUTH_URL);
    }

    static me() {
        return WebService.fetchAPI("/me", "GET", false, WebService.API_AUTH_URL);
    }

    static events() {
        return WebService.fetchAPI("/event", "GET");
    }

    static createEvent(name, description, location, startDatetime, endDatetime, slot, isOpen, remuneration, inscriptionEndDatetime = null) {
        if(!inscriptionEndDatetime) { inscriptionEndDatetime = startDatetime;}
        return WebService.fetchAPI("/event", "POST", {name, description, location, startDatetime, endDatetime, slot, isOpen, remuneration,inscriptionEndDatetime});
    }
    
    static updateEvent(id, name, description, location, startDatetime, endDatetime, slot, isOpen, remuneration, inscriptionEndDatetime = null) {
        if(!inscriptionEndDatetime) { inscriptionEndDatetime = startDatetime;}

        return WebService.fetchAPI("/event/" + id, "PUT", {name, description, location, startDatetime, endDatetime, slot, isOpen, remuneration, inscriptionEndDatetime});
    }

    static participate(eventId, userId, status) {
        return WebService.fetchAPI("/event/" + eventId + "/participate", "POST", {userId, status});
    }

    static users() {
        return WebService.fetchAPI("/user", "GET");
    }

    static user(id) {
        return WebService.fetchAPI("/user/" + id, "GET");
    }

    static comment(event, mark, content) {
        return WebService.fetchAPI("/event/"+event.id+"/comment", "POST", {mark, content});
    }

    static updateUser(id, firstName, lastName, nickname, phone, company, rank, regiment, displayContacts, picturePath, region, associationRole) {
        return WebService.fetchAPI("/user/"+id, "PUT", {
            firstName : firstName,
            lastName : lastName,
            nickname : nickname,
            phone : phone,
            company : company,
            rank : rank,
            regiment : regiment,
            displayContacts : displayContacts,
            picturePath : picturePath,
            region : region,
            associationRole : associationRole
        });
    }

    static regions() {
        return WebService.fetchAPI("/region", "GET");
    }

    static setRegionContact(userId, region) {
        return WebService.fetchAPI("/region", "PUT", {contactId:userId, regionName:region});
    }

    static walletHistory(userId) {
        return WebService.fetchAPI("/wallet/" + userId, "GET");
    }

    static addWallet(userId, amount, comment, file) {

        let formData = new FormData();
        formData.append("amount", amount);
        formData.append("comment", comment);
        formData.append("document", file);

        let headers = {
            //  'Content-Type': 'multipart/form-data',
            'Origin': '/'
        }
        let token = WebService.getToken();
        if (token) {headers['Authorization'] = 'Bearer ' + token;}
        let request  = {method: "POST", headers: headers, body: formData}

        return fetch(WebService.API_URL + "/wallet/" + userId, request);
    }



    static feeHistory(userId) {
        return WebService.fetchAPI("/fee/" + userId, "GET");
    }

    static payFee(userId, year) {
        return WebService.fetchAPI("/fee/" + userId, "POST", {year : year});
    }
}

export default WebService;