import React from "react";
import "./App.css";
import "./index.css";

import WebService from "./Service.js";
import AddToCalendarButton from "./Calendar.js";


// loader.gif
import loader from "./images/loader.gif";


import Utils from "./Utils.js";


// function countParticipants(event) {
//     if(event.participations === null) return null;
    
//     if(event.id===23)
//     return event.participations.filter((participation) => {return participation.status === "ACCEPTED";}).length;
// }

function countParticipants(event) {
    if(event.participations === null) return null;
    let count = 0;
    event.participations.forEach((participant) => {
        if (participant.status === "PRESENT" || participant.status === "PAID" || participant.status === "ACCEPTED") {
            count++;
        }
    });
    return count;
}

// function participantsWithRifle(event) {
//     if(event.participations === null) return 0;
//     let count = 0;
//     event.participations.forEach((participant) => {
//         if (participant.status === "PRESENT" || participant.status === "PAID"  || participant.status === "ACCEPTED") {
//             if(participant.associationRole)




function statusBgColor(status) {
    switch (status) {
        case "REFUSED":
            return "bg-badColor";
        case "PENDING":
            return "bg-waitingColor";
        case "ACCEPTED":
            return "bg-goodColor";
        case "CANCELED":
            return "bg-badColor";
        case "PRESENT":
            return "bg-goodColor";
        case "PAID":
            return "bg-goodColor";
        case null :
            return "";
        default:
            return "text-badColor";
    }
}        

function statusColor(status) {
    switch (status) {
        case "REFUSED":
            return "text-neutralColor";
        case "PENDING":
            return "text-waitingColor";
        case "ACCEPTED":
            return "text-goodColor";
        case "PAID":
            return "text-goodColor";
        case "CANCELED":
            return "text-neutralColor";
        case "PRESENT":
            return "text-goodColor";
        case null :
            return "";
        default:
            return "text-badColor";
    }
}

function statusShort(status) {
    switch (status) {
        case "REFUSED":
            return "Absent";
        case "PENDING":
            return "Incertain";
        case "ACCEPTED":
            return "Inscrit";
        case "CANCELED":
            return "Absent";
        case "PRESENT":
            return "Présent";
        case "PAID":
            return "Présent";
        case null :
            return "";
        default:
            return "";
    }
}

function statusLabel(status) {
    switch (status) {
        case "REFUSED":
            return "Vous vous êtes inscrit absent à cet évènement";
        case "PENDING":
            return "Vous devez encore valider votre inscription";
        case "ACCEPTED":
            return "Votre inscription a été prise en compte";
        case "CANCELED":
            return "Vous n'êtes pas venu à cet évènement";
        case "PRESENT":
            return "Vous avez été noté présent à cet évènement";
        case "PAID":
            return "Vous avez été noté présent à cet évènement";
        case null :
            return "";
        default:
            return "Vous n'êtes pas encore inscrit à cet évènement !";
    }
}





function remainingDays(date) {
    // Days between now and the event (negative if the event is in the past)
    let remainingDays = Math.floor((new Date(date) - new Date()) / (1000 * 60 * 60 * 24));
    return remainingDays;
}


function getUserState(participations) {
    // get currentUser on localstorage
    let user = JSON.parse(localStorage.getItem("currentUser"));
  
    
    if(participations && user) {
        for(let i = 0 ; i < participations.length ; i++) {
            if (participations[i].user.id === user.id) {
                // REFUSED, PENDING, ACCEPTED, CANCELED, PRESENT
                return participations[i].status;
            }
        }
        return "UNKNOWN";
    } 
    return null;


}

export default
class Events extends React.Component {
    
    constructor(props) {
        super(props);
        this.state = {
            events: [],
            actualEvents: [],
            eventModal: false,
            editEventModal : false,
            eventUserState : null,
            event :null,
            users : [],
            selectedFilter : "COMING_EVENTS", // COMING_EVENTS, PASSED_EVENTS, ALL_EVENTS
            selectedOrder : "ASC", // ASC, DESC
            relanceEmailResultData : null,
            sendEmailEnabled : true

        };
    }

    formatDate(date) {
        let formattedDate = new Date(date);
        formattedDate = formattedDate.toLocaleDateString('fr-FR', {year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute:'numeric'});
        // remove 2 hours from the date
        let date_ = new Date(date);
        date_.setHours(date_.getHours() - 2);
        formattedDate = date_.toLocaleDateString('fr-FR', {year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute:'numeric'});             
        return formattedDate;
    }


    componentDidMount() {
        let eventsRequest = WebService.events();
        let user = JSON.parse(localStorage.getItem("currentUser"));
        eventsRequest.then((response) => {
            if (response.status === 200) {
                response.json().then((data) => {
                    let actualEvents = data.sort((a, b) => {return new Date(a.startDatetime) - new Date(b.startDatetime);});
                    actualEvents = actualEvents.filter((event) => {return new Date(event.startDatetime) > new Date();}); 
        
                    let urlParams = new URLSearchParams(window.location.search);
                    let eventId = parseInt(urlParams.get("id"));
                    
                    let event = data.find((event) => {return event.id === eventId;});
                    let eventUserState = null;
                    if(event) {
                        eventUserState = getUserState(event.participations);
                        if(Utils.isAdmin(user)) {
                            WebService.users().then((response) => {
                                if (response.status === 200) {
                                    response.json().then((data) => {
                                        // only user not participating to the event
                                        data = data.filter((user) => !event.participations.find((participation) => {return participation.user.id === user.id;}));
                                        this.setState({users: data});
                                    });
                                }
                            });
                        }
                    }

                    this.setState({event : event, events: data, actualEvents: actualEvents, eventUserState : eventUserState});

                });
            }
        });

        // get non participants users
     


        //                             {(user && Utils.isAdmin(user) && this.state.users.length > 0) && 

 
      
    }




    render() {

        //let urlParams = new URLSearchParams(window.location.search);
        //let eventId = urlParams.get("id");
        //let event = this.state.events.find((event) => {return event.id == eventId;});
        let user = JSON.parse(localStorage.getItem("currentUser"));
        if(this.state.event) {
            
            let remainingDays_ = remainingDays(this.state.event.startDatetime);
            let bgDaysColor = remainingDays_ > 14 ? "bg-goodColor" : 
            remainingDays_ > 7 ? "bg-waitingColor" : 
            remainingDays_ > 0 ? "bg-badColor" : 
            remainingDays_ === 0 ? "bg-badColor" :
                "bg-neutralColor";
    
            let remainingDaysText = remainingDays_ > 0 ? "Dans " + remainingDays_ + " jours" :
            remainingDays_ === 0 ? "C'est demain !" 
            : "Il y a " + Math.abs(remainingDays_) + " jours";
    

            let inscriptionRemainingDays = remainingDays(this.state.event.inscriptionEndDatetime);
            let slots = this.state.event.slot;
            // console.log(this.state.event.slot != 0 && countParticipants(this.state.event) >= this.state.event.slot);
            console.log(countParticipants(this.state.event));
            console.log(this.state.event.slot);
            console.log(this.state.eventUserState);
            return (
                <div className="block">

                    <div className="text-frenchWhite bg-frenchBlack/50 inline-block border-solid border-t-2  border-b-2 border-frenchBrown">
                    <h1 className="font-bold text-center text-xl text-frenchWhite p-3 ">
                        {this.state.event.name} 
                        {Utils.isAdmin(user) &&
                            <button 
                                onClick={() => {this.setState({editEventModal: true});}}
                                type="button" className="ml-1 hover:-translate-y-2 transition duration-500 ease-out-in transform hover:shadow-lg hover:cursor-pointer rounded">
                                <div className="flex flex-col items-center ">
                                    <h3 className="font-bold text-center text-xl text-frenchWhite">✎</h3>
                                </div>
                            </button>
                        }
                    </h1>
                    <div>
                        {this.state.editEventModal &&
                            <EventForm
                                formName = "Modifier l'évènement"
                                validateName="Valider les modifications"
                                defaultName={this.state.event.name}
                                inscriptionEndDatetime={this.state.event.inscriptionEndDatetime}
                                defaultDescription={this.state.event.description}
                                defaultStartDatetime={this.state.event.startDatetime}
                                defaultEndDatetime={this.state.event.endDatetime}
                                defaultLocation={this.state.event.location}
                                defaultMaxParticipants={this.state.event.slot}
                                defaultOpen={this.state.event.isOpen}
                                defaultRefund={this.state.event.remuneration}
                                closeModal = {() => {this.setState({editEventModal: false});}}
                                onSubmit={(name, description, location, startDatetime, endDatetime, maxParticipants, open, refund, inscriptionEndDatetime) => {
                                    let request = WebService.updateEvent(this.state.event.id, name, description, location, startDatetime, endDatetime, maxParticipants, open, refund, inscriptionEndDatetime);
                                    request.then((response) => {
                                        if (response.status === 200) {
                                            response.json().then(() => {
                                                window.location.reload();
                                            });
                                        }
                                    });                                                        
                                }}
                            />
                        }
                    </div>
                    {(this.state.event.remuneration && this.state.event.remuneration > 0 && !Utils.isGuest(Utils.me())) &&
                        <p className="text-center text-frenchWhite p-3">Défraiement : <span className="text-goodColor font-bold">{this.state.event.remuneration}€</span></p>
                    }
                    <p className="text-frenchGray text-center p-3">
                        Du  <span className="font-bold">{this.formatDate(this.state.event.startDatetime)}</span>  au  <span className="font-bold">{this.formatDate(this.state.event.endDatetime)}</span>
                    </p>
                    <p className={"font-bold rounded-md inline-block text-center p-1 " + (bgDaysColor)}>{remainingDaysText}</p>
                    <hr className="mt-3 ml-10 mr-10"></hr>
                                {/* Google map link of location */}

                    <p className="p-3"><a className="" href={"https://www.google.com/maps/search/?api=1&query=" + this.state.event.location} target="_blank" rel="noreferrer"><span className="font-bold">🗺️ Lieu</span> : {this.state.event.location}</a></p>
                    <div className="pl-16 m-auto">
                        <AddToCalendarButton 
                            summary={this.state.event.name}
                            description={this.state.event.description}
                            start= { {
                                dateTime: this.state.event.startDatetime, 
                                timeZone: 'Europe/Paris'
                            }}
                            end= {{
                                dateTime: this.state.event.endDatetime, 
                                timeZone: 'Europe/Paris'
                            }}
                            location={this.state.event.location}
                        />
                    </div>
                    <hr className="mt-3 ml-10 mr-10"></hr>
                    <p className="p-3">{this.state.event.description}</p>
                    

                </div>
                {(remainingDays_ < 0 && Utils.isMember(user)) &&
                    <div>
                        <br></br>

                        <div className="mt-3 text-frenchWhite bg-frenchBlack/50 inline-block border-solid border-t-2  border-b-2 border-frenchBrown">
                            
                            <div className="p-4">
                                <hr className="mt-3 ml-10 mr-10 border-frenchBrown border-2"></hr>
                                <h3 className="font-bold text-center text-xl text-frenchWhite pt-1 ">Poster un commentaire</h3>
                            </div>

                            {/* Is user was present, commentary form with mark and message */}
                            {(this.state.eventUserState === "PRESENT" && !this.state.event.commented) &&
                                <div className="p-4 pt-2 text-left">
                                    <form className="flex flex-col"
                                        onSubmit={(event) => {  
                                            event.preventDefault();
                                            let mark = parseInt(document.getElementById("mark").value);
                                            let message = document.getElementById("message").value;

                                            let request = WebService.comment(this.state.event, mark, message);
                                            request.then((response) => {
                                                if (response.status === 200 || response.status === 201) {
                                                    // add the comment on the this.state.event.comments
                                                    let eventData = this.state.event;
                                                    let comment = {mark : mark, comment : message};
                                                    eventData.commented = true;
                                                    eventData.comments.push(comment);
                                                    this.setState({event : eventData});


                                                }
                                            });
                                                                                               
                                        }}>
                                        <div className="flex flex-col">
                                            {/* Mark between 0 and 5 */}
                                            <label htmlFor="mark" className="text-frenchWhite">Note : </label>
                                            <input type="number" id="mark" name="mark" min="0" max="5" className="rounded border-solid border-2 border-frenchBrown p-1 text-frenchBlack" />
                                        </div>
                                        <div className="flex flex-col">
                                            {/* Message */}
                                            <label htmlFor="message" className="text-frenchWhite">Message : </label>
                                            <textarea id="message" name="message" className="rounded border-solid border-2 border-frenchBrown p-1 text-frenchBlack" />
                                        </div>
                                        <div className="flex flex-col">
                                            <input type="submit" value="Poster" className="p-2 bg-frenchBrown hover:-translate-y-2 transition duration-500 ease-out-in transform hover:shadow-lg hover:cursor-pointer rounded m-2"/>
                                        </div>
                                    </form>
                                </div>
                            }   
                            {this.state.eventUserState !== "PRESENT" &&
                                <div className="p-1">
                                    <p className="text-center font-bold rounded bg-neutralColor p-1">Vous devez être noté présent à l'évènement pour pouvoir le commenter</p>
                                </div>
                            }
                            {(this.state.eventUserState === "PRESENT" && this.state.event.commented) &&
                                <div className="p-1">
                                    <p className="text-center font-bold rounded bg-neutralColor p-1">Vous avez déjà commenté cet évènement</p>
                                </div>
                            }
                            <hr className="mt-5 ml-10 mr-10 border-frenchBrown border-2"></hr>
                            <h3 className="font-bold text-center text-xl text-frenchWhite p-2">Commentaires des participants</h3>
                            <div className="p-1">
                                {/* Display all comments */}
                                {this.state.event.comments.map((comment) =>
                                    <div key={
                                        comment.mark + comment.comment
                                    } className="p-2">
                                        <hr className="mt-3 ml-10 mr-10 border-frenchBrown border-1 "></hr>

                                        <div className="flex flex-row justify-between pt-1">
                                            {/* <p className="font-bold text-frenchWhite">{comment.user.lastName.toUpperCase()} {comment.user.firstName} {comment.user.nickname ? "("+comment.user.nickname+")" : ""}</p> */}
                                            <p className="font-bold text-frenchWhite max-w-2xl">{comment.mark}/5 : {comment.comment}</p>
                                        </div>
                                    </div>
                                )}

                            </div>
                        </div>
                    </div>
                }
                <br></br>

                
                <div className="mt-3 text-frenchWhite bg-frenchBlack/50 inline-block border-solid border-t-2  border-b-2 border-frenchBrown">
                    
                    {/* If event is not passed & admin */}
                    {(remainingDays_ > 0 && Utils.isAdmin(user)) &&
                        <div className="p-4">
                            <hr className="mt-3 ml-10 mr-10 border-frenchBrown border-2"></hr>
                            <h3 className="font-bold text-center text-xl text-frenchWhite p-2 ">Relancer les membres</h3>
                            {/* <button disabled={!this.state.sendEmailEnabled} */}
                            <button disabled={true}
                                onClick={() => {
                                    this.setState({relanceEmailResultData : "pending"});
                                    this.setState({sendEmailEnabled : false});

                                    let request = WebService.sendEventMail(this.state.event.id);
                                    request.then((response) => {
                                        if (response.status === 200) {
                                            response.json().then((data) => {
                                                let emails = data.map((user) => {return user.email;});
                                                this.setState({relanceEmailResultData : {success: true, emails : emails}});
                                            });
                                        } else {
                                            this.setState({sendEmailEnabled : true});

                                            this.setState({relanceEmailResultData : {
                                                success : false,
                                                emails : []
                                            }});
                                        }
                                    });
                                }}
                            className="p-2 bg-frenchBrown hover:-translate-y-2 transition duration-500 ease-out-in transform hover:shadow-lg hover:cursor-pointer rounded m-2">Envoyer des e-mails de relance</button>
                            {this.state.relanceEmailResultData != null &&
                                <div>
                                    {/* <p className="text-center font-bold rounded bg-neutralColor p-1">Les e-mails de relance ont été envoyés</p> */}

                                    <div>
                                        {this.state.relanceEmailResultData !== "pending" &&
                                            <div>
                                                <p className={"text-center font-bold rounded p-1 " + (this.state.relanceEmailResultData.success ? "bg-goodColor" : "bg-badColor")}>
                                                    {this.state.relanceEmailResultData.success ? "Les emails ont bien été envoyés " : "Echec de l'envoi des emails"}
                                                </p>
                                                {this.state.relanceEmailResultData.emails.length > 0 &&
                                                    <p className="text-center font-bold rounded p-1">Cibles des emails : {this.state.relanceEmailResultData.emails.join(", ")}</p>
                                                }
                                            </div>
                                        }

                                        {this.state.relanceEmailResultData === "pending" &&                  
                                            <div className="flex flex-raw items-center  font-bold rounded p-1 bg-neutralColor text-center align-middle">
                                                <img src={loader} alt="loader" className="w-10 h-10"/>    
                                                <p className="">Emails en cours d'envoi...</p>
                                            </div>
                                        }

                                    </div>
                                    
                                </div>
                            }
                        </div>  
                    }


                    <div className="p-4">
                        <hr className="mt-3 ml-10 mr-10 border-frenchBrown border-2"></hr>
                        <h3 className="font-bold text-center text-xl text-frenchWhite p-2 ">Inscription</h3>
                        {(inscriptionRemainingDays > 0 && this.state.event.isOpen && !(slots !== 0 && countParticipants(this.state.event) >= slots)) &&
                        <p className="text-center font-bold text-waitingColor rounded p-1">{"Les inscriptions ferment dans " + inscriptionRemainingDays + " jours"}</p>
                        }

                        {(slots !== 0 && countParticipants(this.state.event) >= slots) &&
                            <p className="text-center font-bold text-frenchWhite rounded bg-neutralColor p-1">L'évènement est complet</p>
                        }

                        {(((this.state.event.isOpen || this.state.eventUserState === "PENDING") && remainingDays_ > 0 && Utils.isMember(user))  && !(this.state.event.slot !== 0 && countParticipants(this.state.event) >= this.state.event.slot)) &&
                            <form 
                                onSubmit={(event) => {
                                    event.preventDefault();
                                    let status = document.querySelector('input[name="participation"]:checked').value;
                                    let request = WebService.participate(this.state.event.id, user.id, status);
                                    request.then((response) => {
                                        if (response.status === 200 || response.status === 201) {
                                            this.setState({eventUserState : status});

                                            let eventData = this.state.event;
                                            // remove previous participation if exusts
                                            eventData.participations = eventData.participations.filter((participation) => {return participation.user.id !== user.id;});
                                            // add the new participation

                                            let participation = {user : user, status : status};
                                            eventData.participations.push(participation);
                                            this.setState({event : eventData});
                                            // window.location.reload();  
                                        }
                                    });                                                        
                                }}
                                className="flex flex-col items-center">
                                <div className="flex flex-row">
                                    <div className={(this.state.eventUserState === "ACCEPTED" ? "bg-goodColor" : "") + " p-1 rounded m-1"}>
                                        <input type="radio" id="present" name="participation" value="ACCEPTED" defaultChecked={this.state.eventUserState === "ACCEPTED"} className="mr-1" />
                                        <label htmlFor="present">Inscrit</label>
                                    </div>
                                    <div className={(this.state.eventUserState === "PENDING" ? "bg-waitingColor" : "") + " p-1 rounded  m-1"}>
                                        <input type="radio" id="maybe" name="participation" value="PENDING" defaultChecked={this.state.eventUserState === "PENDING"} className="mr-1" />
                                        <label htmlFor="maybe">Incertain</label>
                                    </div>
                                    <div className={(this.state.eventUserState === "REFUSED" ? "bg-badColor" : "") + " p-1 rounded m-1"}>
                                        <input type="radio" id="absent" name="participation" value="REFUSED" defaultChecked={this.state.eventUserState === "REFUSED"} className="mr-1"/>
                                        <label htmlFor="absent">Absent</label>
                                    </div>
                                </div>
                                <div>
                                    <input type="submit" value="Valider" className="p-2 bg-frenchBrown hover:-translate-y-2 transition duration-500 ease-out-in transform hover:shadow-lg hover:cursor-pointer rounded m-2"/>
                                </div>
                            </form>
                        }

                        {((!this.state.event.isOpen  && remainingDays_ > 0) || (this.state.event.slot !== 0 && countParticipants(this.state.event) >= this.state.event.slot)) &&
                            <p className="text-center font-bold rounded bg-neutralColor p-1">Les inscriptions sont fermées pour cet évènement</p>
                        }
                        {(remainingDays_ > 0 && !user && this.state.event.isOpen) && 
                            <p className="text-center font-bold rounded "><a className="text-frenchBrown underline"href="/login">Connectez-vous </a> pour vous inscrire à l'évènement</p>
                        }
                        {(user && !Utils.isMember(user) && remainingDays_ > 0 ) &&
                                <p className="text-center font-bold rounded text-badColor p-1">Vous devez être membre de l'association pour vous inscrire à un évènement</p>
                            }
                        {(remainingDays_ < 0 && !user) &&
                            <p className="text-center font-bold rounded bg-neutralColor p-1">L'inscription est impossible lorsque l'évènement est terminé.</p>
                        } 
                        {((remainingDays_ < 0 && user)) && 

                            <div>
                                {(!this.state.eventUserState || this.state.eventUserState === "UNKNOWN")&&
                                    <p className="text-center font-bold rounded bg-neutralColor p-1">L'inscription est impossible lorsque l'évènement est terminé.</p>
                                } 
                                
                                {(this.state.eventUserState === "PENDING" || this.state.eventUserState === "ACCEPTED") &&
                                <p className="text-center font-bold rounded bg-waitingColor p-1">
                                    Votre présence n'a pas encore été confirmée par un administrateur
                                </p>
                                }
                                {(this.state.eventUserState === "CANCELED" && this.state.eventUserState === "REFUSED" ) &&
                                <p className="text-center font-bold rounded bg-badColor p-1">
                                    Vous n'êtes pas venu à cet évènement
                                </p>
                                }
                                {this.state.eventUserState === "PRESENT" &&
                                <p className="text-center font-bold rounded bg-goodColor p-1">
                                    Votre présence a été validée
                                </p>
                                }
                            </div>
                        }                        
                    </div>
                    <div className="p-4">
                        <hr className="mt-3 ml-10 mr-10 border-frenchBrown border-2"></hr>
                        {countParticipants(this.state.event) === 0 &&
                            <h3 className="font-bold text-center text-xl text-frenchWhite p-2 ">Aucun participant pour le moment</h3>
                        }
                        {countParticipants(this.state.event) > 0 &&
                            <h3 className="font-bold text-center text-xl text-frenchWhite p-2 ">{countParticipants(this.state.event)}{this.state.event.slot !== 0 ? "/"+this.state.event.slot : ""} participant{countParticipants(this.state.event) > 1 ? "s" : ""}
                                {/* participants with rifle */}
                            </h3>
                        }
                        <div>
                            {!user &&
                                <p className="text-center font-bold rounded p-1"><a className="text-frenchBrown underline"href="/login">Connectez-vous</a> pour voir la liste des participants</p>
                            }
                            {(user && !Utils.isMember(user)) &&
                                <p className="text-center font-bold rounded text-badColor p-1">Vous devez être membre de l'association pour voir la liste des participants</p>
                            }
    

                            {(user && Utils.isMember(user)) &&
                                <div>
                                    <table className="border-separate text-left  border-spacing-2">
                                        <thead className="border-spacing-3">
                                            <tr key={this.state.event.id} className="">
                                                <th className="">Membre</th>
                                                <th className="text-center">Status</th>
                                                <th className="text-center">Dernière mis à jour</th>

                                                {Utils.isAdmin(user) &&
                                                    <th className="text-center">Actions</th>
                                                }
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {(user && Utils.isAdmin(user) && this.state.users) &&
                                                <tr className=" border-t-2  border-frenchBrown">
                                                    <td className=" table-cell pr-5 py-2">
                                                        
                                                        <select id="users" className="shadow appearance-none border rounded w-full py-2 px-3 text-frenchBlack leading-tight focus:outline-none focus:shadow-outline">
                                                            {this.state.users.sort((a, b) => {return a.lastName.localeCompare(b.lastName);})
                                                            .map((user) => 
                                                                <option key={user.id} value={user.id}>{user.lastName.toUpperCase()} {user.firstName} {user.nickname ? "("+user.nickname+")" : ""}</option>
                                                            )}
                                                        </select>
                                                    </td>
                                                    <td className=" text-center table-cell px-2 py-2 rounded font-bold ">
                                                        <div>
                                                            <select id="status" className="shadow appearance-none border rounded w-full py-2 px-3 text-frenchBlack leading-tight focus:outline-none focus:shadow-outline">
                                                                {remainingDays_ < 0 && <option value="PRESENT">Présent</option> }
                                                                <option value="ACCEPTED">Inscrit</option>
                                                                <option value="PENDING">Incertain</option>
                                                                <option value="REFUSED">Absent (Inscription)</option>
                                                                {remainingDays_ < 0 &&<option value="CANCELED">Absent (Présence)</option>}
                                                            </select>
                                                        </div>
                                                    </td>
                                                    <td></td>

                                                    <td className=" text-center table-cell px-2 py-2 rounded font-bold ">
                                                        <button
                                                            onClick={() => {
                                                                let userId = parseInt(document.getElementById("users").value);
                                                                let status = document.getElementById("status").value;
                                                                let request = WebService.participate(this.state.event.id, userId, status);
                                                                request.then((response) => {
                                                                    if (response.status === 200 || response.status === 201) {
                                                                        // add the user on the this.state.event.participations
                                                                        let event = this.state.event;
                                                                        let user = this.state.users.find((user) => {return user.id === userId;});
                                                                        event.participations.push({user : user, status : status});
                                                                        this.setState({event : event});
                                                                        this.setState({users : this.state.users.filter((user) => {return user.id !== userId;})});
                                                                    }
                                                                });                                                        
                                                            }}
                                                            type="button" className="bg-frenchBrown hover:-translate-y-2 transition duration-500 ease-out-in transform hover:shadow-lg hover:cursor-pointer rounded">
                                                            <div className="flex flex-col items-center ">
                                                                <h3 className="font-bold text-center text-xl text-frenchWhite p-1">Ajouter</h3>
                                                            </div>
                                                        </button>
                                                    </td>
                                                    
                                                </tr>
                                            }

                                            {this.state.event.participations
                                            // sort by date
   
                                            .sort((a, b) => {
                                                // First sort by status
                                                const statusOrder = {
                                                    "PRESENT": 1,
                                                    "ACCEPTED": 2,
                                                    "PENDING": 3,
                                                    "REFUSED": 4,
                                                    "CANCELED": 5
                                                };
                                            
                                                if (statusOrder[a.status] < statusOrder[b.status]) return -1;
                                                if (statusOrder[a.status] > statusOrder[b.status]) return 1;
                                            
                                                // If statuses are the same, sort by updateDate
                                                if (a.updateDate < b.updateDate) return -1;
                                                if (a.updateDate > b.updateDate) return 1;
                                                
                                                return 0;
                                            })
                                            .filter((participation) => {return participation.status !== "NONE";})
                                            .map((participation) => 
                                                <tr key={participation.user.id} className=" border-t-2  border-frenchBrown">
                                                    <td className=" table-cell pr-5 py-2">
                                                        <a href={"/user?id=" + participation.user.id} className="text-frenchWhite">
                                                            {participation.user.lastName.toUpperCase()} {participation.user.firstName} 
                                                        </a>
                                                            {participation.user.nickname ? " ("+participation.user.nickname+")" : ""}</td>
                                                        
                                                    {Utils.isAdmin(user) &&
                                                    <td>
                                                       <div>
                                                        {participation.status !== "PAID" &&
                                                            <select 
                                                                id={"status" + participation.user.id}
                                                                onChange={(event) => {
                                                                    let request = WebService.participate(this.state.event.id, participation.user.id, event.target.value);
                                                                    request.then((response) => {
                                                                        if (response.status === 200 || response.status === 201) {
                                                                            let eventData = this.state.event;
                                                                            let participation_ = eventData.participations.find((participation_) => {return participation_.user.id === participation.user.id;});
                                                                            participation_.status = event.target.value;
                                                                            eventData.participations = eventData.participations.filter((participation_) => {return participation_.user.id !== participation.user.id;});
                                                                            eventData.participations.push(participation_);
                                                                            this.setState({event : eventData});
                                                                        } else {
                                                                            this.setState({event : this.state.event});
                                                                        }
                                                                    });
                                                                }}

                                                             className={"text-center table-cell  py-2 rounded font-bold " + statusBgColor(participation.status)} defaultValue={participation.status}>
                                                                <option className={statusBgColor("ACCEPTED")} value="ACCEPTED">Inscrit</option>
                                                                {remainingDays_ < 0 && <option className={statusBgColor("PRESENT")}value="PRESENT">Présent</option> }
                                                                <option className={statusBgColor("PENDING")} value="PENDING">Incertain</option>
                                                                <option className={statusBgColor("REFUSED")} value="REFUSED">Absent (Inscription)</option>
                                                                {remainingDays_ < 0 &&<option className={statusBgColor("CANCELED")} value="CANCELED">Absent (Présence)</option>}
                                                            </select>
                                                        }
                                                        {participation.status === "PAID" &&
                                                            <p className={"text-center table-cell p-3 py-2 rounded font-bold " + statusBgColor(participation.status)}>Présent (cagnotte mis à jour)</p>
                                                        }
                                                        </div>
                                                        
                                                    </td>
                                                    }
                                                    {/* participation.updateDate =  JJ/MM/AA à HH:SS */} 

                                                    {!Utils.isAdmin(user) &&
                                                        <td 
                                                            id={"status" + participation.user.id}
                                                            className={"text-center table-cell px-2 py-2 rounded font-bold " +statusBgColor(participation.status)}>{statusShort(participation.status)}</td> 
                                                    }
                                                    <td className=" text-center table-cell px-2 py-2 rounded font-bold ">{new Date(participation.updateDate).toLocaleDateString('fr-FR', {year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute:'numeric'})}</td>

                                                    {(Utils.isAdmin(user) && remainingDays_ < 0 && (participation.status === "ACCEPTED" || participation.status === "PENDING")) &&
                                                        <td className=" text-center table-cell">
                                                            <button
                                                                onClick={() => {
                                                                    let request = WebService.participate(this.state.event.id, participation.user.id, "PRESENT");
                                                                    request.then((response) => {
                                                                        if (response.status === 200 || response.status === 201) {
                                                                            let eventData = this.state.event;
                                                                            let participation_ = eventData.participations.find((participation_) => {return participation_.user.id === participation.user.id;});
                                                                            participation_.status = "PRESENT";
                                                                            eventData.participations = eventData.participations.filter((participation_) => {return participation_.user.id !== participation.user.id;});
                                                                            eventData.participations.push(participation_);
                                                                            this.setState({event : eventData});
                                                                            document.getElementById("status" + participation.user.id).value = "PRESENT";
                                                                        }
                                                                    });}}
                                                            
                                                            >
                                                                <div className="flex flex-col items-center ">
                                                                    <h3 className=" text-center text-frenchWhite bg-goodColor rounded m-1 p-1">Marquer présent</h3>
                                                                </div>
                                                            </button>
                                                            <button
                                                                onClick={() => {
                                                                    let request = WebService.participate(this.state.event.id, participation.user.id, "CANCELED");
                                                                    request.then((response) => {
                                                                        if (response.status === 200 || response.status === 201) {
                                                                            let eventData = this.state.event;
                                                                            let participation_ = eventData.participations.find((participation_) => {return participation_.user.id === participation.user.id;});
                                                                            participation_.status = "CANCELED";
                                                                            eventData.participations = eventData.participations.filter((participation_) => {return participation_.user.id !== participation.user.id;});
                                                                            eventData.participations.push(participation_);
                                                                            this.setState({event : eventData});
                                                                            document.getElementById("status" + participation.user.id).value = "CANCELED";
                                                                        }
                                                                    });}}
                                                                >
                                                                <div className="flex flex-col items-center ">
                                                                    <h3 className=" text-center text-frenchWhite bg-badColor rounded m-1 p-1">Marquer Absent</h3>
                                                                </div>
                                                            </button>
                                                        </td>
                                                    }
                                                    
                                                </tr>
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
            );

        } else {


            return (



                <div className=" block">
                    <div className="text-frenchWhite bg-frenchBlack/50 inline-block border-solid border-t-2  border-b-2 border-frenchBrown mb-5">
                        <h1 className="font-bold text-center text-xl text-frenchWhite p-3 ">Filtrer les évènements</h1>
                        {/* FIlter les évènements avec un radio button */}

                        <div>
                            {/* If not enough space, do as row, else, do as column */}
                            <div className="flex flex-col justify-center p-2 sm:flex-row">
                                <div className="flex flex-row items-center">
                                    <input 
                                        onClick={() => {
                                            let actualEvents = this.state.events.filter((event) => {
                                                let remainingDays_ = remainingDays(event.startDatetime);
                                                return remainingDays_ > 0;
                                            });
                                            this.setState({actualEvents : actualEvents});


                                        }}
                                    type="radio" id="comingEvents" name="filter" value="COMING_EVENTS" defaultChecked={this.state.selectedFilter === "COMING_EVENTS"} className="mr-1" onChange={(event) => {this.setState({selectedFilter : event.target.value});}}/>
                                    <label htmlFor="comingEvents">Évènements à venir</label>
                                </div>
                                <div className="flex flex-row items-center p-2">
                                    <input 
                                        onClick={() => {
                                            let actualEvents = this.state.events.filter((event) => {
                                                let remainingDays_ = remainingDays(event.startDatetime);
                                                return remainingDays_ < 0;
                                            });
                                            this.setState({actualEvents : actualEvents});
                                        }}
                                    type="radio" id="passedEvents" name="filter" value="PASSED_EVENTS" defaultChecked={this.state.selectedFilter === "PASSED_EVENTS"} className="mr-1" onChange={(event) => {this.setState({selectedFilter : event.target.value});}}/>
                                    <label htmlFor="passedEvents">Évènements passés</label>
                                </div>
                                <div className="flex flex-row items-center p-2">
                                    <input 
                                        onClick={() => {
                                            this.setState({actualEvents : this.state.events});
                                        }}
                                    type="radio" id="allEvents" name="filter" value="ALL_EVENTS" defaultChecked={this.state.selectedFilter === "ALL_EVENTS"} className="mr-1" onChange={(event) => {this.setState({selectedFilter : event.target.value});}}/>
                                    <label htmlFor="allEvents">Tous les évènements</label>
                                </div>
                            </div>
                        </div>
                        {/* Ordre croissant/décroissant*/}
                        <div className="flex flex-row justify-center p-2">
                            <div className="flex flex-row items-center">
                                <input 
                                    onClick={() => {
                                        
                                        // Trier par éloignement par rapport à auhoud'hui (new Date()) et pas en comparant les dates entre elles
                                        let actualEvents = this.state.actualEvents.sort((a, b) => {
                                            let a_remainingDays = Math.abs(remainingDays(a.startDatetime));
                                            let b_remainingDays = Math.abs(remainingDays(b.startDatetime));
                                            return a_remainingDays - b_remainingDays;
                                        });

                                        


                                        this.setState({actualEvents : actualEvents});
                                    }}
                                type="radio" id="asc" name="order" value="ASC" defaultChecked={this.state.selectedOrder === "ASC"} className="mr-1" onChange={(event) => {this.setState({selectedOrder : event.target.value});}} />
                                <label htmlFor="asc">Plus récents</label>
                            </div>
                            <div className="flex flex-row items-center p-2">
                                <input 
                                    onClick={() => {
                                        // Trier par éloignement par rapport à auhoud'hui (new Date()) et pas en comparant les dates entre elles
                                        let actualEvents = this.state.actualEvents.sort((a, b) => {
                                            let a_remainingDays = Math.abs(remainingDays(a.startDatetime));
                                            let b_remainingDays = Math.abs(remainingDays(b.startDatetime));
                                            return b_remainingDays - a_remainingDays;
                                        });
                                        
                                        this.setState({actualEvents : actualEvents});
                                    }}
                                type="radio" id="desc" name="order" value="DESC" defaultChecked={this.state.selectedOrder === "DESC"} className="mr-1" onChange={(event) => {this.setState({selectedOrder : event.target.value});}}/>
                                <label htmlFor="desc">Plus anciens</label>
                            </div>
                        </div>
                    </div>
                    




                    {Utils.isMember(user) &&
                        <div>
                            <button type="button" 
                                    onClick={() => {this.setState({eventModal: true});}}
                                    className="bg-frenchBrown hover:-translate-y-2 transition duration-500 ease-out-in transform hover:shadow-lg hover:cursor-pointer rounded"
                            >
                                <div className="flex flex-col items-center ">
                                    <h3 className="font-bold text-center text-xl text-frenchWhite p-3 ">Créer un évènement</h3>
                                </div>
                            </button>
                            {this.state.eventModal &&
                                <EventForm
                                    formName = "Créer un évènement"
                                    validateName="Valider l'évènement"
                                    defaultName=""
                                    defaultDescription=""
                                    inscriptionEndDatetime=""
                                    defaultStartDatetime=""
                                    defaultEndDatetime=""
                                    defaultLocation=""
                                    defaultMaxParticipants=""
                                    defaultOpen={true}
                                    defaultRefund={0}
                                    closeModal = {() => {this.setState({eventModal: false});}}
                                    onSubmit={(name, description, location, startDatetime, endDatetime, maxParticipants, open, refund, inscriptionEndDatetime) => {
                                    let request = WebService.createEvent(name, description, location, startDatetime, endDatetime, maxParticipants, open, refund, inscriptionEndDatetime);
                                    request.then((response) => {
                                        if (response.status === 200) {
                                            response.json().then((data) => {
                                                window.location.reload();
                                            });
                                        }
                                    });                                                        
                                }}
                                />
                            }
                        </div>            
                    }
                    <div className="flex flex-col items-center">
                        {this.state.actualEvents.map((event) => <EventBox 
                            key={event.id}
                            isOpen={event.isOpen}
                            name={event.name}
                            slot={event.slot}
                            id={event.id}
                            inscriptionEndDatetime={event.inscriptionEndDatetime}
                            participations={event.participations}
                            startDatetime={event.startDatetime}
                            endDatetime={event.endDatetime}
                            countParticipants={countParticipants(event)}
                        />)}

                    </div>
                </div>
            );
        }
    }
}


class EventForm extends React.Component {


    render() {
        let defaultStartDatetime = null;
        if(this.props.defaultStartDatetime) {
            defaultStartDatetime = this.props.defaultStartDatetime.slice(0, -10);
        }

        let defaultEndDatetime = null;
        if(this.props.defaultEndDatetime) {
            defaultEndDatetime = this.props.defaultEndDatetime.slice(0, -10);
        }

        let defaultInscriptionEndDatetime = null;
        if(this.props.inscriptionEndDatetime) {
            defaultInscriptionEndDatetime = this.props.inscriptionEndDatetime.slice(0, -10);
        }
        

        return (
        <div className="fixed z-10 inset-0 overflow-y-auto">
            <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                <div className="fixed inset-0 transition-opacity" aria-hidden="true">
                    <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
                </div>
                <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
                <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full bg-frenchBlack/75">
                    <div className="border-frenchBrown border-2">
                        <div className="text-center">
                            <h3 className="text-lg  bg-frenchBrown text-frenchWhite font-bold" id="modal-title">
                                {this.props.formName}
                            </h3>
                            <div className="text-frenchWhite">
                                <form
                                    onSubmit={(event) => {

                                        event.preventDefault();
                                        let name = document.getElementById("name").value;
                                        let description = document.getElementById("description").value;
                                        let startDatetime = document.getElementById("startDatetime").value;
                                        let endDatetime = document.getElementById("endDatetime").value;
                                        let location = document.getElementById("location").value;
                                        let maxParticipants = parseInt(document.getElementById("maxParticipants").value);
                                        let open = document.getElementById("open").checked;
                                        let refund = parseInt(document.getElementById("refund").value);
                                        let inscriptionEndDatetime = document.getElementById("inscriptionEndDatetime").value;
                                        this.props.onSubmit(name, description, location, startDatetime, endDatetime, maxParticipants, open, refund, inscriptionEndDatetime);
                                    }}
                                >
                                    <div className="bg-frenchBlack/50 p-5">
                                        <div className="mb-4">
                                            <label className="block text-frenchWhite text-sm font-bold mb-2" htmlFor="name">
                                                Nom de l'évènement
                                            </label>
                                            <input defaultValue={this.props.defaultName ? this.props.defaultName : ""} type="text" id="name" placeholder="Nom de l'évènement" className="shadow appearance-none border rounded w-full py-2 px-3 text-frenchBlack leading-tight focus:outline-none focus:shadow-outline" />
                                        </div>
                                        <div className="mb-4">
                                            <label className="block text-frenchWhite text-sm font-bold mb-2" htmlFor="description">
                                                Description
                                            </label>
                                            <textarea defaultValue={this.props.defaultDescription ? this.props.defaultDescription : ""} type="text" id="description" placeholder="Description" className="shadow appearance-none border rounded w-full py-2 px-3 text-frenchBlack leading-tight focus:outline-none focus:shadow-outline h-32" />
                                        </div>
                                        <div className="mb-4">
                                            <label className="block text-frenchWhite text-sm font-bold mb-2" htmlFor="startDatetime">
                                                Date de début
                                            </label>
                                            <input 
                                                defaultValue={defaultStartDatetime ? defaultStartDatetime : ""}
                                                type="datetime-local" id="startDatetime" placeholder="Date de début" className="shadow appearance-none border rounded w-full py-2 px-3 text-frenchBlack leading-tight focus:outline-none focus:shadow-outline" />
                                        </div>
                                        <div className="mb-4">
                                            <label className="block text-frenchWhite text-sm font-bold mb-2" htmlFor="endDatetime">
                                                Date de fin
                                            </label>
                                            <input 
                                                defaultValue={defaultEndDatetime ? defaultEndDatetime : ""}
                                                type="datetime-local" id="endDatetime" placeholder="Date de fin" className="shadow appearance-none border rounded w-full py-2 px-3 text-frenchBlack leading-tight focus:outline-none focus:shadow-outline" />
                                        </div>
                                        {/* Fermeture des inscriptions inscriptionEndDatetime*/}
                                        <div className="mb-4">
                                            <label className="block text-frenchWhite text-sm font-bold mb-2" htmlFor="location">
                                                Date de cloture des inscriptions
                                            </label>
                                            <input
                                                defaultValue={defaultInscriptionEndDatetime ? defaultInscriptionEndDatetime : ""}
                                                type="datetime-local" id="inscriptionEndDatetime" placeholder="Cloture des inscriptions" className="shadow appearance-none border rounded w-full py-2 px-3 text-frenchBlack leading-tight focus:outline-none focus:shadow-outline" />
                                        </div>


                                        <div className="mb-4">
                                            <label className="block text-frenchWhite text-sm font-bold mb-2" htmlFor="location">
                                                Lieu
                                            </label>
                                            <input 
                                                defaultValue={this.props.defaultLocation ? this.props.defaultLocation : ""}
                                                type="text" id="location" placeholder="Lieu" className="shadow appearance-none border rounded w-full py-2 px-3 text-frenchBlack leading-tight focus:outline-none focus:shadow-outline" />
                                        </div>
                                        <div className="mb-4">
                                            <label className="block text-frenchWhite text-sm font-bold mb-2" htmlFor="maxParticipants">
                                                Nombre de places disponnibles (0 si pas de limite)
                                            </label>
                                            <input 
                                                defaultValue={this.props.defaultMaxParticipants ? this.props.defaultMaxParticipants : ""}
                                                type="number" id="maxParticipants" placeholder="Nombre de places disponnibles" className="shadow appearance-none border rounded w-full py-2 px-3 text-frenchBlack leading-tight focus:outline-none focus:shadow-outline" />
                                        </div>
                                        <div className="mb-4">
                                            <label className=" text-frenchWhite text-sm font-bold mb-2" htmlFor="open">
                                                Ouvert à l'inscription
                                            </label>
                                            <input 
                                                defaultChecked={this.props.defaultOpen ? this.props.defaultOpen : false}
                                                type="checkbox" id="open" className="ml-2" />
                                        </div>
                                        <div className="mb-4">
                                            <label className="text-frenchWhite text-sm font-bold mb-2" htmlFor="refund">
                                                Défraiement
                                            </label>
                                            <input 
                                                defaultValue={this.props.defaultRefund ? this.props.defaultRefund : 0}
                                                type="number" id="refund" placeholder="0" className="ml-2  text-frenchBlack" />
                                        </div>
                                        <div className="flex justify-center">
                                            <input type="submit" className=" font-bold text-center text-xl text-frenchWhite p-3 bg-frenchBrown hover:-translate-y-2 transition duration-500 ease-out-in transform hover:shadow-lg hover:cursor-pointer rounded  m-1" value={this.props.validateName}></input>
                                            <button onClick={() => {this.props.closeModal();}} type="button" className="bg-frenchBrown hover:-translate-y-2 transition duration-500 ease-out-in transform hover:shadow-lg hover:cursor-pointer rounded  m-1">
                                                <div className="flex flex-col items-center ">
                                                    <p className="font-bold text-center text-xl text-frenchWhite p-3">Annuler</p>
                                                </div>
                                            </button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        );
    }
}


class EventBox extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            remainingDays: 0,
            remainingDaysForInscription: 0,
            countParticipants: 0,
            userStatus : null
        };
    }


    componentDidMount() {
        this.setState({remainingDays: remainingDays(this.props.startDatetime)});
        this.setState({userStatus: getUserState(this.props.participations)});
        this.setState({remainingDaysForInscription: remainingDays(this.props.inscriptionEndDatetime)});
        // console.log(this.props.inscriptionEndDatetime);
        // console.log(remainingDays(this.props.inscriptionEndDatetime));

    }

    formatDate(date) {
        let formattedDate = new Date(date);
        //formattedDate = formattedDate.toLocaleDateString('fr-FR', {weekday: 'long', year: 'numeric', month: 'long', day: 'numeric'/*, hour: 'numeric', minute:'numeric'*/});
        //formattedDate = formattedDate.charAt(0).toUpperCase() + formattedDate.slice(1);

        // format : 26 mai 2021 
        let day = formattedDate.getDate();
        let month = formattedDate.toLocaleString('fr-FR', {month: 'long'});
        let year = formattedDate.getFullYear();
        formattedDate = day + " " + month + " " + year;

        return formattedDate;
    }

    render() {
        // Like 26 mai 2021 à 18:00

        let bgDaysColor = this.state.remainingDays > 14 ? " bg-goodColor" : 
        this.state.remainingDays > 7 ? " bg-waitingColor " : 
        this.state.remainingDays > 0 ? " bg-badColor" :
        this.state.remainingDays === 0 ? " bg-badColor" :
        " bg-neutralColor";

        let remainingDaysText = 
        this.state.remainingDays > 0 ? "Dans " + this.state.remainingDays + " jours" : 
        this.state.remainingDays === 0 ? "C'est demain !" : 
        "Il y a " + Math.abs(this.state.remainingDays) + " jours";
        let isEventFull = this.props.slot !== 0 && this.props.countParticipants >= this.props.slot;
        return (

            <a href={"/event?id=" + this.props.id} onClick={() => {window.location.href = "/event?id=" + this.props.id;}} 
                //if media width is > 400, w-96, else nothing
                className={
                    " sm:w-96 " + 
                    " p-3 mt-10 bg-frenchBlack/75 rounded hover:-translate-y-2 transition duration-500 ease-out-in transform hover:shadow-lg hover:cursor-pointer"}>
                <hr className="border-frenchBrown border-2 w-full "/>

                <div className="flex flex-col items-center ">
                    <h3 className="font-bold text-center text-xl text-frenchWhite p-3 ">{this.props.name}
                    {(!this.props.isOpen && this.state.remainingDays > 0) && 
                            <span className="text-badColor"> (Inscriptions fermées)</span>
                        }
                    </h3>
                    <p className={"font-bold rounded-md inline-block text-center text-frenchWhite p-1 " + (bgDaysColor)}>{remainingDaysText}</p>
                    {/* <p className="text-center text-frenchWhite p-3">{this.props.event.description}</p> */}
                    {(this.props.countParticipants != null) && 
                        <p className="text-center text-frenchBrown p-3 font-bold">
                                {
                                    this.props.countParticipants === 0 && this.props.slot === 0 ? 
                                    "Aucun participant" : 
                                    this.props.countParticipants === 0 ? 
                                        "Aucun participant ("+this.props.slot+" places disponnibles)" : 
                                    this.props.countParticipants === 1 && this.props.slot === 0 ?
                                        "1 participant" : 
                                    this.props.countParticipants === 1 ?
                                        "1/"+this.props.slot+" participant" : 
                                    this.props.slot === 0 ?
                                        this.props.countParticipants + " participants" :
                                    
                                        this.props.countParticipants + "/"+this.props.slot+" participants"
                                }
                        </p>
                    }
                        <div>
                    
                        {( ( !(this.props.slot !== 0 && this.props.countParticipants >= this.props.slot) &&this.props.isOpen && this.state.remainingDaysForInscription >= 0 && this.state.remainingDaysForInscription !== this.state.remainingDays)) &&
                            <p className="text-center text-waitingColor font-bold  p-3">{"Les inscriptions ferment dans " + this.state.remainingDaysForInscription + " jours"}</p>
                        }

                        {(this.state.userStatus === "UNKNOWN" && !(this.props.slot !== 0 && this.props.countParticipants >= this.props.slot)) &&
                            <div>
                                {this.props.isOpen && this.state.remainingDays >= 0 &&
                                    <p className="text-center font-bold text-badColor p-1">{statusLabel(this.state.userStatus)}</p>
                                }
                            </div>
                        
                        }

                        {/* If event is full*/}
                        {isEventFull &&
                            <p className="text-center font-bold text-frenchWhite rounded bg-neutralColor p-1">Evènement complet</p>
                        }



                        { ((this.state.remainingDays >= 0 && 
                            this.state.userStatus !== "UNKNOWN"  )) &&
                            <div>
                                
                            {!(this.state.userStatus === "PENDING" && isEventFull) &&
                                <p className={"font-bold "+ statusColor(this.state.userStatus)}>{statusLabel(this.state.userStatus)}</p>
                            }
                            </div>
                        }

                        {this.state.remainingDays < 0 &&
                            <p className="font-bold rounded-md inline-block text-center text-neutralColor p-1">Evènement terminé</p>
                        }   
                        </div>
                    <p className="text-center text-frenchWhite p-3">{"Du "+this.formatDate(this.props.startDatetime) + " au " + this.formatDate(this.props.endDatetime) }</p>
                </div>
            </a>
        );
    }
}


