import React from "react";
import "./App.css";
import "./index.css";

import WebService from "./Service.js";

// loader
import loader from "./images/loader.gif";


import {Page} from './App.js';

export default
class Register extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            message: "",
            messageColor: "text-badColor",
            loading: false
        };
        this.formSubmit = this.formSubmit.bind(this);
    }

  async formSubmit(event) {
    event.preventDefault();
    let form = event.target;
    let email = form.email.value;
    let password = form.password.value;
    let passwordConfirm = form.passwordConfirm.value;
    let firstName = form.firstName.value;
    let lastName = form.lastName.value;
    // let terms = form.terms.checked;
    let terms = true;
    let acceptMail = form.acceptMail.checked;

    if (password !== passwordConfirm) {
        this.setState({message: "Les mots de passe ne correspondent pas.", messageColor: "text-badColor"});
        return;
    }

    // mot de passe avec au moins 1 chiffre, 1 lettre minuscule, 1 lettre majuscule et 8 caractères minimum
    // let passwordRegex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z]{8,}$/;
    // if (!passwordRegex.test(password)) {
    //     this.setState({message: "Mot de passe trop simple !", messageColor: "text-badColor"});
    //     return;
    // }

    if (!terms) {
        this.setState({message: "Vous devez accepter les conditions d'utilisation.", messageColor: "text-badColor"});
        return;
    }



    let request = WebService.register(email, password, firstName, lastName, acceptMail)
    this.setState({loading: true});
    request.then((response) => {
        this.setState({loading: false});
        this.setState({message: "Inscription en cours...", messageColor: "text-neutralColor"});

        if (response.status === 201) {
            this.setState({message: "Inscription réussie, un email de confirmation vous a été envoyé. Vous pouvez dorénavant vous connecter.", messageColor: "text-goodColor"});
        } else {
            let message = response.status === 409 ? "Vous êtes déjà inscrit" : "Erreur lors de l'inscription.";
            this.setState({message: message, messageColor: "text-badColor"});

        }
    });
  }

  render() {
    /*
        Login form
        - Email 
        - Prénom 
        - Nom 
        - Mot de passe	
        - Confirmation	
    */
        return (
            <div className="h-screen">
               
                <form className="bg-frenchBlack/75 inline-block text-frenchWhite text-left  border-frenchBrown border-2 rounded-md w-1/2 m-auto
                " onSubmit={this.formSubmit}>
                <div className="">
                    <h3 className="text-center text-xl  text-frenchWhite p-3 bg-frenchBrown">Inscription</h3>
                </div>
                    <div>
                        <div className="">
                            <div className="p-5 flex flex-col">
                                <label htmlFor="lastName">Nom</label>
                                <input className="p-1 rounded-sm text-frenchBlack" type="text" id="lastName" name="lastName" placeholder="Doe" />
                            </div>
                            <div className="p-5 flex flex-col">
                                <label htmlFor="firstName">Prénom</label>
                                <input className="p-1 rounded-sm text-frenchBlack" type="text" id="firstName" name="firstName" placeholder="John" />
                            </div>
                        </div>
                        <div className="p-5">
                            <div className="">
                                <div><label htmlFor="email">Email</label></div>
                                <div><input className="w-full p-1 rounded-sm text-frenchBlack" type="email" id="email" name="email" placeholder="john.doe@gmail.com"  autoComplete="username"/></div>
                            </div>
                        </div>
                        <div className="">
                            <div className="p-5 flex flex-col">
                                <label htmlFor="password">Mot de passe</label>
                                <input className="p-1 rounded-sm text-frenchBlack" type="password" id="password" name="password" placeholder="**************" autoComplete="new-password" />
                            </div>
                            <div className="p-5 flex flex-col">
                                <label htmlFor="passwordConfirm">Confirmation</label>
                                <input className="p-1 rounded-sm text-frenchBlack" type="password" id="passwordConfirm" name="passwordConfirm" placeholder="**************" autoComplete="new-password"/>
                            </div>  
                        </div>
                        {/* The text dont have to increase the widht of the entire container*/}
                        <div className="flex flex-row space-y-2 ">
                            <div className="p-5">
                                <input type="checkbox" id="acceptMail" name="acceptMail" defaultChecked />
                                {/* Dont increase the width of the form */}
                                <label className="pl-2 " htmlFor="acceptMail"> J'accepte de reçevoir des e-mails sur les annonces internes de l'association et de reçevoir des e-mails de relance sur les évènements de l'association.</label>
                            </div>
                        </div>

                        <div className="flex flex-row space-y-2">
                            <div className="p-5">
                                <button className="bg-frenchBrown rounded p-2 px-4" type="submit">S'inscrire</button>
                            </div>
                            <div className="p-5">
                                <input type="checkbox" id="terms" name="terms" />
                                <label className="pl-2" htmlFor="terms"> J'accepte les <a href={"/"+Page.Terms} className="text-frenchBrown underline" onClick={this.props.setRegisterPage} >conditions d'utilisation</a></label>
                            </div>
                        </div>
                    </div>
                    <div className="pb-2 font-bold">
                        {this.state.loading ? <img src={loader} alt="loader" className="m-auto w-10" /> : ""}
                        <p className={"text-center "+this.state.messageColor}>{this.state.message}</p>
                    </div>
                    <hr className="border-frenchWhite border-1" />
                    <div className="pt-5 p-3">
                        <p className="text-center">
                            Déjà inscrit ? <a href={"/"+Page.Login}className=" text-frenchBrown underline" onClick={this.props.setLoginPage}>Se connecter</a>
                        </p>
                    </div>
                </form>
            </div>
        );
    }
}


