import React from "react";
import "./App.css";
import "./index.css";

// import logoImage from "./images/logo.png";
// import logo2Image from "./images/corps_logo.png";

//import borderImage from "./images/border.png";


import Utils from "./Utils.js";

import defaultProfileImage from "./images/guest.jpg";

import WebService from './Service.js';

import {Page} from './App.js';

const PageComment = {
    Actualities: "Bienvenue sur le site du Corps d'Observation du Rhin !",
    Events: "Historique des évènements du Corps d'Observation du Rhin",
    Albums: "Albums photo & souvenirs de reconstitution",
    Troc: "TROC",
    Login: "",
    Register: "",
    Budget: "Cagnotte & budget associatif du Corps d'Observation du Rhin",
    JoinUs: "Pour rejoindre l'association, n'hésitez pas à nous contacter !"
};

function currentComment(page) {
    switch(page) {
        case Page.Actualities:
            return PageComment.Actualities;
        case Page.Events:
            return PageComment.Events;
        case Page.Albums:
            return PageComment.Albums;
        case Page.Troc:
            return PageComment.Troc;
        case Page.Login:
            return PageComment.Login;
        case Page.Register:
            return PageComment.Register;
        case Page.Budget:
            return PageComment.Budget;
        case Page.JoinUs:
            return PageComment.JoinUs;
        case Page.Profile:
            return "";
        default:
            return "";

    }

}



function pendingEvents(events) {
    let user = localStorage.getItem("currentUser") ? JSON.parse(localStorage.getItem("currentUser")) : null;
    let pendingEvents = 0;
    if(user) {
        let now = new Date();

        for(let i = 0 ; i < events.length; i++) {
            let event = events[i];

            if(!event.isOpen) {
                continue;
            } 

            if(event.startDatetime < now) {
                continue;
            }
            
            if(event.participations) {
                for(let j = 0 ; j < event.participations.length ; j++) {
                    let participation = event.participations[j];
                    if (participation.user.id === user.id && participation.status === "PENDING") {
                        pendingEvents++;
                    }
                }
            }
        }
    }
    return pendingEvents;

}

function unseenEvents(events) {


    // Events count where the user have no participation data
    let user = localStorage.getItem("currentUser") ? JSON.parse(localStorage.getItem("currentUser")) : null;
    if(!Utils.isMember(user)) {
        return 0;
    }

    let unseenEvents = 0;
    let now = new Date();
    if(user ) {
        for(let i = 0 ; i < events.length ; i++) {
            let hasParticipated = false;
            let event = events[i];

            if(!event.isOpen) {
                continue;
            } 
            
            if(event.startDatetime < now) {
                continue;
            }
            if(!event.participations) {
                return 0;
            }
            for(let j = 0 ; j < event.participations.length ; j++) {
                let participation = event.participations[j];
                if (participation.user.id === user.id ) {
                    hasParticipated = true;
                    break;
                }
            }
            
            if(!hasParticipated) {
                unseenEvents++;
            }
        }
    }
    return unseenEvents;
}


export default
class Header extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            comment: currentComment(this.props.page),
            unseenEvents: 0,
            pendingEvents: 0,
            adminNotif: 0,
            equipmentNotif: 0,
            missingFees: 0,
            feePaid: true
        };

    }

    // mount
    componentDidMount() {
        let me =  JSON.parse(localStorage.getItem("currentUser"));

        if(Utils.isAdmin(me)) {
            WebService.missingFees().then((response) => {
                if (response.status === 200) {
                    response.json().then((data) => {
                        this.setState({missingFees: data});
                    });
                }
            });

            WebService.missingConfirmation().then((response) => {
                if (response.status === 200) {
                    response.json().then((data) => {
                        this.setState({adminNotif: this.state.adminNotif + data});
                    });
                }
            });
        }

        if(Utils.isMember(me)) {
            
        WebService.feeHistory(me.id).then((response) => {
            if (response.status === 200) {
                response.json().then((data) => {
                    let currentYear = new Date().getFullYear();
                    let nextYear = new Date().getFullYear() + 1;
                    let isFeePaid = false;
                    for(let i = 0; i < data.length; i++) {
                        if(data[i].year == currentYear || data[i].year == nextYear) {
                            isFeePaid = true;
                            break
                        }
                    }
                    this.setState({feePaid: isFeePaid});
                });
            }});


            WebService.equipement().then((response) => {
                if (response.status === 200) {
                    response.json().then((equipment) => {
                        WebService.myEquipments().then((response) => {
                            if (response.status === 200) {
                                response.json().then((myEquipments) => {
                                    let equipmentNotif = 
                                    equipment
                                        // Remove "Autre" category
                                        .filter((e) => e.category !== "Autre")
                                        // Remove equipment in user equipment
                                        .filter((e) => !myEquipments.find((me) => me.equipment.id === e.id))
                                        .length;
                                    this.setState({equipmentNotif: equipmentNotif});
                                });
                            }
                        });
                    });
                }
            });
        }

        let eventsRequest = WebService.events();
        eventsRequest.then((response) => {
            if (response.status === 200) {
                response.json().then((data) => {

                    if(Utils.isAdmin(me)) {
                        this.setState({adminNotif: this.state.adminNotif + Utils.events_presence_not_validated(data).length});
                        WebService.getTickets().then((response) => {
                            if (response.status === 200) {
                                response.json().then((data) => {
                                    this.setState({adminNotif: this.state.adminNotif + data.filter((ticket) => {return !ticket.done}).length});
                                });
                            }
                        });
                    }


                    data = data.filter((event) => {return new Date(event.startDatetime) > new Date();}); // Filter past events
                    this.setState({unseenEvents: unseenEvents(data), pendingEvents: pendingEvents(data)});
                });
            }
        });
    }

    setPageURL(page) {
        this.props.setPage(page);
        // set the URL last element to the page
        //window.history.pushState({}, page, "/" + page);
        this.setState({comment: currentComment(page)});
    }


    render() {
        let eventNotifColor = "bg-badColor";
        let eventNotifNumber = this.state.unseenEvents;

        if(eventNotifNumber <= 0) {
            eventNotifColor = "bg-waitingColor";
            eventNotifNumber = this.state.pendingEvents;
        }

        return (
            // background image backgroundImage1
            <header className="">
            <div className=" flex justify-between items-center p-4">
                <div className="flex justify-center flex-wrap flex-grow space-x-5 "> {/* sm:flex-nowrap */}
                
                    <HeaderElement linkPage={this.props.page} isCurrentPage={this.props.page === Page.Actualities} title="Actualités" setPage={() => {
                        this.setPageURL(Page.Actualities);
                        }} available={true}/>
                    <HeaderElement linkPage={this.props.page} isCurrentPage={this.props.page === Page.Events} title="Évènements" 
                        notifNumber={eventNotifNumber} 
                        notifColor={eventNotifColor}
                        setPage={() => {this.setPageURL(Page.Events);}} 
                        available={true}/>
                    <HeaderElement linkPage={this.props.page} isCurrentPage={this.props.page === Page.Albums} title="Albums"  setPage={() => {
                        this.setPageURL(Page.Albums);
                        }} available={true}/>
                    {/* <HeaderElement linkPage={this.props.page} isCurrentPage={this.props.page === Page.Troc} title="Zone de troc"  setPage={() => {
                        this.setPageURL(Page.Troc);
                        }} /> */}
                        {Utils.isOnlyGuest(Utils.me())  &&
                        <HeaderElement linkPage={this.props.page} isCurrentPage={this.props.page === Page.Budget} title="Budget & Cagnotte"  setPage={() => {
                            this.setPageURL(Page.Budget);
                            }} 
                            notifNumber={this.state.feePaid ? 0 : 1}
                            notifColor={"bg-badColor"}
                            available={true}/>
                        }

                    {Utils.me() === null &&
                        <HeaderElement linkPage={this.props.page} isCurrentPage={this.props.page === Page.Login} title="Connectez-vous"  setPage={() => {
                            this.setPageURL(Page.Login);
                            }} available={true} />  
                    }
                        
                    {Utils.me() === null &&
                        <HeaderElement linkPage={this.props.page} isCurrentPage={this.props.page === Page.Register} title="Inscrivez-vous"  setPage={() => {
                            this.setPageURL(Page.Register);
                            }} available={true} />  
                    }

                    {/* <HeaderElement linkPage={this.props.page} isCurrentPage={this.props.page === Page.Documentation} title="Documentation"  setPage={() => {
                        this.setPageURL(Page.Documentation);
                        }} available={false}/> */}
                    {/* <HeaderElement linkPage={this.props.page} isCurrentPage={this.props.page === Page.Commands} title="Bons de commande"  setPage={() => {
                        this.setPageURL(Page.Commands);
                        }} available={false}/> */}
                    {/* <HeaderElement linkPage={this.props.page} isCurrentPage={this.props.page === Page.Shop} title="Échoppe"  setPage={() => {
                        this.setPageURL(Page.Shop);
                        }} available={false}/> */}
                    {Utils.isMember(this.props.user) &&
                        <HeaderElement linkPage={this.props.page} isCurrentPage={this.props.page === Page.Logistique} title="Mon équipement" 
                            notifColor="bg-badColor"
                            notifNumber={this.state.equipmentNotif}    
                        setPage={() => {
                            this.setPageURL(Page.Logistique);
                            }} available={true}/>
                    }
                    {Utils.isAdmin(this.props.user) &&
                        <HeaderElement linkPage={this.props.page} 
                                        notifNumber={this.state.adminNotif > 0 ? this.state.adminNotif : this.state.missingFees} 
                                        notifColor={this.state.adminNotif > 0 ? "bg-badColor" : "bg-waitingColor"}
                        isCurrentPage={this.props.page === Page.Administration} title="Administration"  available={true}setPage={() => {
                            this.setPageURL(Page.Administration);
                            }} />
                    }
                    {/* {(this.props.user === null || !Utils.isMember(this.props.user)) &&
                        <HeaderElement  linkPage={this.props.page}
                                        notifNumber={0}
                                        notifColor={""}
                                        isCurrentPage={this.props.page === Page.JoinUs} title="Rejoignez-nous"  setPage={() => {
                                            this.setPageURL(Page.JoinUs);
                                        }} 
                                        available={true} />
                                            
                    } */}

                </div>
                <div className="ml-auto mr-5">
                    <ProfileElement 
                        user={this.props.user}
                        setLoginPage={() => {
                            this.setPageURL(Page.Login);
                        }} 
                        setRegisterPage={() => {
                            this.setPageURL(Page.Register);
                        }} 
                        logout={() => { this.props.logout(); }}
                        setProfilePage={() => {
                            this.setPageURL(Page.Profile+"?id="+this.props.user.id);
                        }}
                        />
                </div>
            </div>
            {this.state.comment &&
                <div className="flex flex-row justify-center space-x-5 items-center p-4 mt-4 pb-10">
                    <h3 className=" bg-frenchBlack/25 border-2 p-4 border border-frenchGold text-frenchWhite font-bold">
                        {this.state.comment}
                    </h3>
                </div>
            }
            </header>
        );
    }
}




class ProfileElement extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            dropDownOpen: false,
            user: null,
            profilePicture: defaultProfileImage
        }
    }

    toggleDropDown() {
        this.setState({dropDownOpen: !this.state.dropDownOpen})
        
    }

    componentDidMount() {
        // this.setState({user: user ? user : null})
        let user = JSON.parse(localStorage.getItem("currentUser"));
        if(user)  {
            setTimeout(async () => {
                this.setState({profilePicture : await Utils.profilePicture(user)});
            }, 0);
        } else {
            this.setState({profilePicture: defaultProfileImage});
        
        }
    }
    

    render() {
        let user = JSON.parse(localStorage.getItem("currentUser"));

        // Drop
        return (
            <div className="ProfileElement relative"
                onMouseEnter={() => this.toggleDropDown()}
                onMouseLeave={() => this.toggleDropDown()}
                
            >
                <img className="rounded-full border-frenchGold border-2 w-20 h-20" src={this.state.profilePicture} alt="profile" />
                <div className="flex flex-col justify-center items-center">

                    {(this.state.dropDownOpen && !user) && 
                        <div className="absolute z-10 bg-frenchWhite/100  border-1 border-frenchGold translate-y-10 ">
                            <p className="p-2 font-bold hover:border-b-2 border-frenchGold transition delay-150 duration-150 ease-out transform">
                                <a href={"/"+Page.Login} onClick={this.props.setLoginPage}>Connexion</a>
                            </p>
                            <p className="p-2 font-bold hover:border-b-2 border-frenchGold transition delay-150 duration-150 ease-out transform">
                                <a href={"/"+Page.Register} onClick={this.props.setRegisterPage}>Inscription</a>
                            </p>
                            {/* <p className="p-2 font-bold hover:border-b-2 border-frenchGold transition delay-150 duration-150 ease-out transform">
                                <a href={"/bug"} >Contacter un administrateur</a> 
                            </p> */}
                        </div>
                    }    
                    
                    {(this.state.dropDownOpen && user) && 
                        <div className="absolute z-10 bg-frenchWhite/100  border-1 border-frenchGold translate-y-16">
                            <p className="p-2 font-bold hover:border-b-2 border-frenchGold transition delay-150 duration-150 ease-out transform">
                                <a href={"/"+Page.Profile + "?id=" + user.id} onClick={this.props.setProfilePage}>Profil</a>
                            </p>
                            <p className="p-2 font-bold hover:border-b-2 border-frenchGold transition delay-150 duration-150 ease-out transform">
                                <a href={"/"+Page.Actualities} onClick={this.props.logout}>Déconnexion</a> 
                            </p>
                            {/* Rapporter un bug */}
                            <p className="p-2 font-bold hover:border-b-2 border-frenchGold transition delay-150 duration-150 ease-out transform">
                                <a href={"/bug"} >Contacter un administrateur</a> 
                            </p>

                        </div>
                    }  
                </div>
            </div>  
        );
    }
}

class HeaderElement extends React.Component {
    // constructor(props) {
    //     super(props)
    //   }
    render() {
        let classes ="";
        if(this.props.available) {
            classes =
                this.props.isCurrentPage 
                ? "text-frenchBrown underline"
                : "text-frenchWhite transation delay-150 duration-150 hover:text-frenchBrown hover:underline hover:ease-out hover:transform";
                classes += " text-2xl font-bold cursor-pointer";
        } else {
            classes="text-gray text-2xl font-bold cursor-not-allowed";
        }
        
        return (
            <div className="HeaderElement flex inline-block">
                <h2 className={classes}>
                    <a 
                        className={this.props.available ? "cursor-pointer" : "cursor-not-allowed"}
                    href={"/"+this.props.linkPage}
                        onClick={
                            (e) => {
                                //e.preventDefault();
                                if(this.props.available) {
                                    this.props.setPage();
                                }
                            }
                        }
    >
            {this.props.title} 
                    </a>
                </h2>
                    {(this.props.notifNumber !== 0 && this.props.notifColor ) &&
                        <span className={"rounded-full font-bold text-center inline-block text-frenchWhite p-1 ml-1 pl-2 pr-2 " + this.props.notifColor}>{this.props.notifNumber}</span>
                    }
            </div>
        );
    }
}