import React from "react";

import WebService from './Service.js';

import Utils from "./Utils.js";

import loader from "./images/loader.gif";


// CSS
import "./App.css";

export default
class Administration extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            user : null,
            events : null,
            eventActionStatus : {
                message : "",
                status : "DEFAULT" // PENDING, SUCCESS, ERROR
            },
            tickets : [],
            users : [],

        };
    }


    events_presence_not_validated() {
        return Utils.events_presence_not_validated(this.state.events);
    }


    is_event_ready_for_validation(event) {
        // If there is no ACCEPTED in participations and at least 1 PRESENT.
        let eventParticipationReady = event.participations.filter((participation) => {
            return participation.status === "ACCEPTED";
        }).length === 0 && event.participations.filter((participation) => {
            return participation.status === "PRESENT";
        });
        return (eventParticipationReady.length > 0);
    }
        
        

    participations_not_validated(event) {
    
        return event.participations.filter((participation) => {
            return participation.status === "ACCEPTED" || participation.status === "PRESENT" || participation.status === "PENDING" || participation.status === "CANCELED";
        });
        
    }

    componentDidMount() {

        this.setState({user : JSON.parse(localStorage.getItem("currentUser"))});
        if(Utils.isAdmin(Utils.me())) {
        
            WebService.getTickets().then((response) => {
                if (response.status === 200) {
                    response.json().then((data) => {
                        data = data.sort((a, b) => {
                            return a.done - b.done || new Date(b.createdAt) - new Date(a.createdAt);
                        });
                        this.setState({tickets: data});
                    });
                }
            });
            this.update_event_list();

            WebService.users().then((response) => {
                if (response.status === 200) {
                    response.json().then((data) => {
                        this.setState({users: data});


                        WebService.wallets().then((response) => {
                            if (response.status === 200) {
                                response.json().then((data) => {
                                    let usersWithWallets = this.state.users.map((user) => {
                                        let wallet = data.filter((wallet) => wallet.ownerId === user.id).reduce((total, wallet) => total + wallet.amount, 0)
                                        user.wallet = wallet;
                                        return user;
                                    }); 
                                    this.setState({users: usersWithWallets});
                                });
                            }
                        });

                        WebService.fees().then((response) => {
                            if (response.status === 200) {
                                response.json().then((data) => {
                                    let currentYear = new Date().getFullYear().toString();

                                    let usersWithFees = this.state.users.map((user) => {
                                        let fee = data.find((fee) => fee.ownerId === user.id && fee.year === currentYear);
                                        user.fee = fee ? true : false;

                                        return user;
                                    });
                                    this.setState({users: usersWithFees});
                                });
                            }
                        });

                    });
                }
            });

        }
    }

    update_event_list() {
        let request = WebService.events();
        request.then((response) => {
            if (response.status === 200) {
                response.json().then((data) => {
                    this.setState({events: data});
                });
            }
        });
    }

    render() {

        return (
                <div>
                    {!Utils.isAdmin(this.state.user) &&
                        <h1 className="bg-badColor p-3 rounded  text-frenchWhite font-bold m-auto inline"> Vous n'avez pas le droit d'accéder à cette page ! </h1>
                    }

                    {Utils.isAdmin(this.state.user) &&
                    // ALIGN VERTICALLY
                    <div className="flex flex-col items-center">
                        <div className="text-frenchWhite bg-frenchBlack/50 inline-block border-solid border-t-2  border-b-2 border-frenchBrown mb-5">
                            {/* <hr className="mt-3 ml-10 mr-10 border-frenchBrown border-2"></hr> */}
                            <hr className="mt-3 ml-10 mr-10 border-frenchBrown border-2"></hr>

                            <h3 className="font-bold text-center text-xl  p-2 text-frenchBrown">Valider les présences aux évènements</h3>
                             {/*Status  */}
                             {/* Text dont increase the size of the button status label */}
                             {/* Center content without m-auto (to avoid the fact that the container is increased*/}

                            {/* If no event */}
                            {this.events_presence_not_validated().length === 0 &&
                                <p className="text-frenchWhite bg-neutralColor p-1 m-2 font-bold rounded text-center">Aucun évènement en attente de validation.</p>
                            }
                            {/* If there are events */}

                            <div className="text-center  font-bold text-lg divide-x-2 display-inline m-auto max-w-2xl">
                                {this.state.eventActionStatus.status === "PENDING" &&
                                // MAde elemnts aligned horizontally
                                <div className="bg-neutralColor p-2 rounded  text-frenchWhite">
                                    <img src={loader} alt="loader" className="w-10 h-10 m-auto"/> 
                                    <p className="">{this.state.eventActionStatus.message}</p>
                                </div>
                                }
                                {this.state.eventActionStatus.status === "SUCCESS" &&
                                    <p className="bg-goodColor p-2 rounded  text-frenchWhite">{this.state.eventActionStatus.message}</p>
                                }
                                {this.state.eventActionStatus.status === "ERROR" &&
                                    <p className="bg-badColor p-2 rounded  text-frenchWhite">{this.state.eventActionStatus.message}</p>
                                }
                            </div>
                        
                            <div className="align-left">
                                {this.events_presence_not_validated().map((event) => {
                                    return (
                                        <div key={event.id} className="m-2 mt-6 ">
                                            
                                            <a className="text-frenchWhite font-bold text-xl mb-4 " href={"/event?id=" + event.id}>
                                                {event.name}
                                            </a>
                                            { event.remuneration > 0 &&
                                            <button className={"bg-goodColor p-1 rounded m-3 rounded " + (this.is_event_ready_for_validation(event) ? "bg-goodColor" : "bg-neutralColor")}  onClick={() => {
                                                if(this.is_event_ready_for_validation(event) && this.state.eventActionStatus.status !== "PENDING") {
                                                    // static participate(eventId, userId, status)

                                                    let participationsToUpdate = event.participations.filter((participation) => {
                                                        return participation.status === "PRESENT";
                                                    });

                                                    this.setState({eventActionStatus : {status : "PENDING", message : 
                                                        "Mise à jour des cagnottes de l'évènement " + event.name + " (+" + event.remuneration + "€) pour les membres " +
                                                            (participationsToUpdate.map((participation) => { return participation.user.firstName + " " + participation.user.lastName.toUpperCase(); }).join(", ")) + " en cours..."
                                                        
                                                    }});
                                                    let responsesCount = 0;
                                                    let failures = 0;
                                                    participationsToUpdate.forEach(element => {

                                                        let request = WebService.participate(event.id, element.user.id, "PAID");
                                                        request.then((response) => {
                                                            responsesCount++;
                                                            if (response.status === 200) {
                                                            } else {
                                                                failures++;
                                                            }

                                                            if(responsesCount === participationsToUpdate.length) {
                                                                if(failures === 0) {
                                                                    this.setState({eventActionStatus : {status : "SUCCESS", message : 
                                                                        "Mise à jour des cagnottes de l'évènement " + event.name + " (+" + event.remuneration + "€) pour les membres " +
                                                                            (participationsToUpdate.map((participation) => { return participation.user.firstName + " " + participation.user.lastName.toUpperCase(); }).join(", ")) + " effectuée avec succès."
                                                                            
                                                                    }});
                                                                    this.update_event_list();
                                                                } else {
                                                                    this.setState({eventActionStatus : {status : "ERROR", message : 
                                                                        "Mise à jour des cagnottes de l'évènement " + event.name + " (+" + event.remuneration + "€) pour les membres " +
                                                                            (participationsToUpdate.map((participation) => { return participation.user.firstName + " " + participation.user.lastName.toUpperCase(); }).join(", ")) + " échouée."
                                                                    }});
                                                                }
                                                            }

                                                        });
                                                    });
                                                } else {
                                                    this.setState({eventActionStatus : {status : "ERROR", message : "Il faut que tous les membres inscrit aient été marqués présent ou absent pour valider les cagnottes."}});
                                                }
                                            }}>
                                                Mettre à jour les cagnottes (+{event.remuneration}€)
                                            </button>
                                            }
                                            <hr></hr>   
                                            <table className="w-full p-4">
                                                <tbody>

                                                {this.participations_not_validated(event).map((participation) => {
                                                    return (
                                                    <tr key={participation.user.id}>
                                                            <td>
                                                                <a className="text-left text-frenchWhite font-bold" href={"/user?id=" + participation.user.id}>
                                                                {participation.user.firstName} {participation.user.lastName.toUpperCase()} {participation.user.nickname ? `(${participation.user.nickname})` : ""}
                                                                </a>
                                                            </td>
                                                            <td>
                                                                {(participation.status === "ACCEPTED" || participation.status ==="PENDING") && 
                                                                <div>                                                              
                                                                    <button className="bg-goodColor text-frenchWhite p-2 rounded" onClick={() => {
                                                                        
                                                                        this.setState({eventActionStatus : {status : "PENDING", message : "Mise à jour de la présence de " + participation.user.firstName + " " + participation.user.lastName.toUpperCase() + " en cours..."}});
                                                                        let request = WebService.participate(event.id, participation.user.id, "PRESENT");
                                                                        request.then((response) => {
                                                                            if (response.status === 200) {
                                                                                this.setState({eventActionStatus : {status : "SUCCESS", message : "Mise à jour de la présence de " + participation.user.firstName + " " + participation.user.lastName.toUpperCase() + " effectuée avec succès."}});
                                                                                this.update_event_list();
                                                                            } else {
                                                                                this.setState({eventActionStatus : {status : "ERROR", message : "Mise à jour de la présence de " + participation.user.firstName + " " + participation.user.lastName.toUpperCase() + " échouée."}});
                                                                            }
                                                                        });

                                                                    }}>Marquer présent</button>
                                                                    <button className="bg-badColor text-frenchWhite p-2 rounded" onClick={() => {

                                                                        this.setState({eventActionStatus : {status : "PENDING", message : "Mise à jour de la présence de " + participation.user.firstName + " " + participation.user.lastName.toUpperCase() + " en cours..."}});
                                                                        let request = WebService.participate(event.id, participation.user.id, "CANCELED");
                                                                        request.then((response) => {
                                                                            if (response.status === 200) {
                                                                                this.setState({eventActionStatus : {status : "SUCCESS", message : "Mise à jour de la présence de " + participation.user.firstName + " " + participation.user.lastName.toUpperCase() + " effectuée avec succès."}});
                                                                                this.update_event_list();
                                                                            } else {
                                                                                this.setState({eventActionStatus : {status : "ERROR", message : "Mise à jour de la présence de " + participation.user.firstName + " " + participation.user.lastName.toUpperCase() + " échouée."}});
                                                                            }
                                                                        });
                                                                    }}>Marquer absent</button>
                                                                </div>
                                                                }
                                                                {participation.status === "PRESENT" && 
                                                                    <button className="bg-goodColor text-frenchWhite p-2 rounded" onClick={() => {

                                                                        this.setState({eventActionStatus : {status : "PENDING", message : "Mise à jour de la présence de " + participation.user.firstName + " " + participation.user.lastName.toUpperCase() + " en cours..."}});
                                                                        let request = WebService.participate(event.id, participation.user.id, "ACCEPTED");
                                                                        request.then((response) => {
                                                                            if (response.status === 200) {
                                                                                this.setState({eventActionStatus : {status : "SUCCESS", message : "Mise à jour de la présence de " + participation.user.firstName + " " + participation.user.lastName.toUpperCase() + " effectuée avec succès."}});
                                                                                this.update_event_list();
                                                                            } else {
                                                                                this.setState({eventActionStatus : {status : "ERROR", message : "Mise à jour de la présence de " + participation.user.firstName + " " + participation.user.lastName.toUpperCase() + " échouée."}});
                                                                            }
                                                                        });

                                                                    }}>Présence validée</button>
                                                                }
                                                                {participation.status === "CANCELED" &&
                                                                    <button className="bg-badColor text-frenchWhite p-2 rounded" onClick={() => {
                                                                            
                                                                            this.setState({eventActionStatus : {status : "PENDING", message : "Mise à jour de la présence de " + participation.user.firstName + " " + participation.user.lastName.toUpperCase() + " en cours..."}});
                                                                            let request = WebService.participate(event.id, participation.user.id, "ACCEPTED");
                                                                            request.then((response) => {
                                                                                if (response.status === 200) {
                                                                                    this.setState({eventActionStatus : {status : "SUCCESS", message : "Mise à jour de la présence de " + participation.user.firstName + " " + participation.user.lastName.toUpperCase() + " effectuée avec succès."}});
                                                                                    this.update_event_list();
                                                                                } else {
                                                                                    this.setState({eventActionStatus : {status : "ERROR", message : "Mise à jour de la présence de " + participation.user.firstName + " " + participation.user.lastName.toUpperCase() + " échouée."}});
                                                                                }
                                                                            });
    
                                                                        }}>Absence validée</button>
                                                                }
                                                            </td>
                                                    </tr>
                                                    );

                                                })}
                                                </tbody>
                                            </table>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                            {/* <div classname="bg-frenchBlack/50 inline-block border-solid border-t-2  border-b-2 border-frenchBrown mb-5">
                                <div className="text-center font-bold text-lg  display-inline m-auto max-w-2xl mt-10 ">
                                    <hr className="mt-3 ml-10 mr-10 border-frenchBrown border-2"></hr>
                                    <h3 className="font-bold text-center text-xl p-2 text-frenchBrown">Paramètres globaux</h3>
                                </div>
                            </div> */}



                            <div className="bg-frenchBlack/50 inline-block border-solid border-t-2  border-b-2 border-frenchBrown mb-5">
                                <div className="text-center font-bold text-lg  display-inline m-auto max-w-2xl mt-10">
                                    <hr className="mt-3 ml-10 mr-10 border-frenchBrown border-2"></hr>
                                    <h3 className="font-bold text-center text-xl p-2 text-frenchBrown">Tickets</h3>
                                    {/* If all tickets are done*/}
                                    {this.state.tickets.filter((ticket) => { return !ticket.done; }).length === 0 &&
                                        <p className="text-frenchWhite bg-neutralColor p-1 m-2 rounded text-center">Aucun ticket en attente.</p>
                                    }
                                    <div className="align-left">
                                                {this.state.tickets
                                                // done == false 
                                                .filter((ticket) => { return !ticket.done; })
                                                .map((ticket) => {
                                                    return (
                                                        <div key={ticket.id} className="m-2 mt-6 ">
                                                            <hr/>
                                                            <div key={ticket.id} className="m-2 mt-6 ">
                                                                <p className="text-frenchBrown font-bold text-center   ">Ticket n°{ticket.id} émit le {new Date(ticket.createdAt).toLocaleDateString()}</p>
                                                                <p className="text-frenchWhite text-left ">
                                                                Auteur : <a className="text-frenchWhite   underline" href={"/user?id=" + ticket.author.id}> {ticket.author.firstName} {ticket.author.lastName.toUpperCase()}</a> ({ticket.author.nickname})
                                                                </p>
                                                                <p className="text-frenchWhite text-left ">
                                                                    Contact : {ticket.author.email} {ticket.author.phone ? " - " + ticket.author.phone : ""}
                                                                </p>
                                                                <div className="mt-5">
                                                                    <p className="text-frenchWhite  text-left ">Contenu :</p>
                                                                    <p className="text-frenchWhite font-light text-left ">
                                                                    {ticket.content}
                                                                    </p>
                                                                </div>
                                                                <p className="text-frenchWhite  text-left mt-4">Pièce jointe : {ticket.attachment && <a className="text-frenchBrown underline" href={ticket.attachment} rel="noreferrer" target="_blank">Télécharger</a>}
                                                                {!ticket.attachment && <span className="text-gray"> Aucune</span>}
                                                                </p>

                                                                {/* Align horizontal */}
                                                                <div className="mt-5 ">
                                                                    <p className={"text-frenchWhite font-bold inline-block p-2 rounded text-left " + (!ticket.done ? "bg-waitingColor" : "bg-neutralColor")}>
                                                                        {ticket.done ? "Résolu" : "En attente"}
                                                                    </p>
                                                                    {!ticket.done &&
                                                                        <button className="bg-goodColor p-2 rounded m-3 rounded text-frenchWhite" onClick={() => {
                                                                            let request = WebService.resolveTicket(ticket.id);
                                                                            request.then((response) => {
                                                                                if (response.status === 200) {
                                                                                    this.setState({eventActionStatus : {status : "SUCCESS", message : "Ticket n°" + ticket.id + " résolu avec succès."}});
                                                                                    let tickets = this.state.tickets;
                                                                                    // Replace the ticket by the new one
                                                                                    tickets[tickets.findIndex((t) => { return t.id === ticket.id; })] = {...ticket, done : true};
                                                                                    // done == false first and then by date
                                                                                    tickets = tickets.sort((a, b) => {
                                                                                        return a.done - b.done || new Date(b.createdAt) - new Date(a.createdAt);
                                                                                    });

                                                                                    this.setState({tickets : tickets});
                                                                                } else {
                                                                                    this.setState({eventActionStatus : {status : "ERROR", message : "Résolution du ticket n°" + ticket.id + " échouée."}});
                                                                                }
                                                                            });
                                                                        }}> Résoudre
                                                                        </button> 
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                    );
                                                }
                                            )}
                                    </div>
                                </div>       
                            </div>


                            
                            <div className="bg-frenchBlack/50 inline-block border-solid border-t-2  border-b-2 border-frenchBrown mb-5">
                                <div>
                                    <hr className="mt-3 ml-10 mr-10 border-frenchBrown border-2"></hr>

                                    <h2 className="text-frenchBrown text-xl p-3 ml-5 font-bold">
                                        Membres non-validés 
                                    </h2>

                                    {this.state.users.filter((user) => user.associationRole === "NONE").length === 0 &&
                                        <p className="text-frenchWhite bg-neutralColor p-1 m-2 rounded text-center inline font-bold">Aucun membre en attente de validation.</p>
                                    }
                                    {this.state.users.filter((user) => user.associationRole === "NONE").length > 0 &&
                                    <div>
                                        <p className="text-frenchWhite bg-badColor p-1 m-2 rounded text-center inline font-bold">{this.state.users.filter((user) => user.associationRole === "NONE").length} utilisateurs sont en attente de validation </p>
                                        
                                        <div className="" >
                                            <select defaultValue={""} id="user_select" className="shadow appearance-none border rounded w-2/12 py-2 px-3 text-frenchBlack leading-tight focus:outline-none focus:shadow-outline">
                                                {this.state.users.filter((user) => user.associationRole === "NONE").map((user) => <option key={user.id} value={user.id}>{user.lastName.toUpperCase()} {user.firstName}</option>)}
                                            </select>
                                            <button onClick=
                                                {(event) => {
                                                    event.preventDefault();
                                                    let id = parseInt(document.getElementById("user_select").value);
                                                    this.setState({addMemberLoading : true});
                                                    WebService.setRole(id, "MEMBER").then((response) => {
                                                        if (response.status === 200) {
                                                            let users = this.state.users;
                                                            users = users.map((user) => {
                                                                if(user.id === id) {
                                                                    user.associationRole = "MEMBER";
                                                                }
                                                                return user;
                                                            });
                                                            this.setState({users: users, addMemberLoading : false});
                                                            this.setState({users: users});
                                                        }
                                                    });
                                                }}
                                            className=" font-bold text-center text-xl text-frenchWhite p-1 bg-frenchBrown hover:-translate-y-2 transition duration-500 ease-out-in transform hover:shadow-lg hover:cursor-pointer rounded m-1">
                                                Faire membre
                                            </button>
                                            <button onClick=
                                                {(event) => {
                                                    event.preventDefault();
                                                    let id = parseInt(document.getElementById("user_select").value);
                                                    this.setState({addMemberLoading : true});
                                                    WebService.setRole(id, "GUEST").then((response) => {
                                                        if (response.status === 200) {
                                                            let users = this.state.users;
                                                            users = users.map((user) => {
                                                                if(user.id === id) {
                                                                    user.associationRole = "GUEST";
                                                                }
                                                                return user;
                                                            });
                                                            this.setState({users: users, addMemberLoading : false});
                                                            this.setState({users: users});
                                                        }
                                                    });
                                                }}
                                            className=" font-bold text-center text-xl text-frenchWhite p-1 bg-frenchBrown hover:-translate-y-2 transition duration-500 ease-out-in transform hover:shadow-lg hover:cursor-pointer rounded m-1">
                                                Faire invité
                                            </button>
                                            <button onClick=
                                                {(event) => {
                                                    event.preventDefault();
                                                    let id = parseInt(document.getElementById("user_select").value);
                                                    this.setState({addMemberLoading : true});
                                                    WebService.setRole(id, "IGNORE").then((response) => {
                                                        if (response.status === 200) {
                                                            let users = this.state.users;
                                                            users = users.map((user) => {
                                                                if(user.id === id) {
                                                                    user.associationRole = "IGNORE";
                                                                }
                                                                return user;
                                                            });
                                                            this.setState({users: users, addMemberLoading : false});
                                                            this.setState({users: users});
                                                        }
                                                    });
                                                }}
                                            className=" font-bold text-center text-xl text-frenchWhite p-1 bg-frenchBrown hover:-translate-y-2 transition duration-500 ease-out-in transform hover:shadow-lg hover:cursor-pointer rounded m-1">
                                                Ignorer
                                            </button>
                            
                                            {this.state.addMemberLoading &&
                                                <div className="flex flex-col items-center">
                                                    <img src={loader} alt="loading" className="w-10 h-10"/>
                                                    <p className="text-gray text-center p-3">Envoi d'une notification à l'utilisateur...</p>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                    }

                                </div>




                                <div className="text-center font-bold text-lg  display-inline m-auto max-w-2xl mt-10 ">
                                <hr className="mt-3 ml-10 mr-10 border-frenchBrown border-2"></hr>
                                    <h3 className="font-bold text-center text-xl p-2 text-frenchBrown mb-4">Nos Membres</h3>
                                </div>
                                

                                <p className="text-waitingColor inline p-2 p-1 m-2 rounded text-center">Cotisations manquantes pour l'année {new Date().getFullYear()} : <span className="font-bold">{
                                    this.state.users
                                        // Font take in count the user IGNORE and GUEST
                                        .filter((user) => Utils.isMember(user))
                                        .filter((user) => !Utils.isGuest(user))
                                        .filter((user) => !user.fee).length
                                
                                }</span> </p>

                                {/* Nos membres */}
                                <table className="ml-5 text-left mx-auto w-11/12 whitespace-pre-wrap">
                        <thead className="border-frenchBrown border-b-2">
                            <tr>
                                <th className="text-frenchWhite p-2">Nom</th>
                                <th className="text-frenchWhite text-center p-2">Rôle</th>
                                <th className="text-frenchWhite text-center p-2">Roleplay</th>
                                <th className="text-frenchWhite text-center p-2">Contact</th>
                                <th className="text-frenchWhite  text-center p-2">Région</th>
                                <th>
                                    <span className="text-frenchWhite p-2">Cagnotte</span>
                                </th>
                                <th>
                                    <span className="text-frenchWhite p-2">Cotisation {
                                        new Date().getFullYear()}</span>
                                </th>
                                
                            </tr>
                        </thead>
                        <tbody className="border-frenchBrown border-b-2 ">
                            {this.state.users
                                .sort((a, b) => a.lastName.localeCompare(b.lastName))
                                .filter((user) => user.associationRole !== "NONE")
                                .sort((a, b) => Utils.roleImportance(a) - Utils.roleImportance(b))
                                .map((user) => {
                                return (
                                    <tr className="border-frenchBrown text-l font-normal border-b-2
                                    " key={user.id}>
                                        <td className="text-frenchWhite  p-1 pl-6 text-left">
                                            <a className="hover:text-frenchBrown hover:underline transition duration-300"href={"/user?id=" + user.id}>{user.lastName.toUpperCase()} {user.firstName}</a> {user.nickname && "(" + user.nickname + ")"}                      
                                        </td>
                                        <td className="text-frenchWhite  p-1 pl-6 text-left">
                                                <div>
                                                    <select 
                                                        onChange={(event) => {
                                                            let id = user.id;
                                                            let role = event.target.value;
                                                            WebService.setRole(user.id, role).then((response) => {
                                                                if (response.status === 200) {
                                                                        let users = this.state.users;
                                                                        users = users.map((user) => {
                                                                            if(user.id === id) {
                                                                                user.associationRole = role;
                                                                            }
                                                                            return user;
                                                                        });
                                                                        this.setState({users: users});
                                                                }
                                                            });
                                                        }}

                                                    
                                                    defaultValue={user.associationRole ? user.associationRole : ""} id="role" className="shadow appearance-none border rounded w-full py-2 px-3 text-frenchBlack leading-tight focus:outline-none focus:shadow-outline">
                                                        <option value="MEMBER">Membre</option>
                                                        <option value="DESK">Bureau</option>
                                                        <option value="PHOTOGRAPHER">Photographe</option>
                                                        <option value="PRESIDENT">Président</option>
                                                        <option value="TREASURER">Trésorier</option>
                                                        <option value="SECRETARY">Secrétaire</option>
                                                        <option value="GUEST">Invité</option>
                                                        <option value="IGNORE">Ignoré</option>
                                                        <option value="NONE">Aucun</option>
                                                    </select>
                                                </div>
                                                {/* <div>
                                                    {Utils.roleLabels(user)}
                                                </div> */}
                                        </td>
                                        <td className="text-frenchWhite  p-1 pl-6 text-left">
                                            {Utils.roleplayLabel(user)}
                                        </td>
                                        <td className="text-frenchWhite  p-1 pl-6 text-left">
                                            {(user.phone && user.phone !== "") && Utils.formatPhone(user.phone)}
                                            {user.email && <p>{user.email}</p>}
                                        </td>
                                        <td className="text-frenchWhite  p-1 pl-6 text-left">
                                            {user.region && user.region.region}
                                        </td>
                                        <td>
                                        {(!Utils.isGuest(user) && Utils.isMember(user)) &&
                                                <div>
                                                    <span className={"p-1 pl-6 text-left font-bold " + (user.wallet > 0 ? "text-goodColor" : user.wallet < 0 ? "text-badColor" : "text-waitingColor")}>{user.wallet} €</span> 
                                                </div>
                                        }
                                        </td>
                                        <td>
                                            {(!Utils.isGuest(user) && Utils.isMember(user)) &&
                                                <div>
                                                    
                                                    <span className={" p-1 pl-6 text-left font-bold " + (user.fee ? " text-goodColor" : " text-waitingColor")}>{user.fee ? "À jour" : "Impayée"}</span> 
                                                    {!user.fee &&
                                                        
                                                            <button className=" 
                                                            bg-frenchBrown text-frenchWhite hover:-translate-y-2 transition duration-500 ease-out-in transform hover:shadow-lg hover:cursor-pointer rounded  m-1"
                                                                onClick={() => {
                                                                    let id = user.id;
                                                                    let year = (new Date().getFullYear()).toString();
                                                                    WebService.payFee(id, year).then((response) => {
                                                                        if (response.status === 200) {
                                                                            let users = this.state.users;
                                                                            users = users.map((user) => {
                                                                                if(user.id === id) {
                                                                                    user.fee = true;
                                                                                }
                                                                                return user;
                                                                            });
                                                                            this.setState({users: users});
                                                                        }
                                                                    });
                                                                }}
                                                            >
                                                                Marquer
                                                            </button>
                                                        
                                                    }
                                                </div>
                                            }
                                        </td>
                                    </tr>
                                )
                            })}
                            
                            
                        </tbody>
                    </table>
                            </div>
                        </div>                
                    }
                </div>
        )
    }


}
