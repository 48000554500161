import React from "react";

import facebook from './images/facebook.png';
import instagram from './images/instagram.png';
import youtube from './images/youtube.png';
import abeille from './images/abeille.png';

export default  
class Footer extends React.Component {
    //  Make the footer still on the bottom of the page
    render() {
        return (
            <footer className="border-frenchBrown border-t-2 bg-frenchDark text-frenchWhite text-center text-sm p-3 bottom-0 w-full mt-auto">
                {/* Main div */}
                <div className="flex flex-row justify-between">

                    {/* First column (networks)*/}
                    <div className="flex flex-col">
                        <p className="font-bold text-lg">Nos réseaux</p>
                        <div className="flex flex-row justify-center">
                            <a href="https://www.facebook.com/Corps-dobservation-du-rhin-178677332678668" rel="noreferrer"   target="_blank" className="mx-1"><img src={facebook} alt="Facebook" className="w-6 h-6"/></a>
                            <a href="https://www.instagram.com/corps_d_observation_du_rhin/" rel="noreferrer"   target="_blank" className="mx-1"><img src={instagram} alt="Instagram" className="w-6 h-6"/></a>
                            <a href="https://www.youtube.com/@corpsdobservationdurhin4879" rel="noreferrer"   target="_blank" className="mx-1"><img src={youtube} alt="Youtube" className="w-6 h-6"/></a>
                        </div>

                    </div>
                    {/* Second coulmn (COpiright) */}
                    <div>
                        <img src={abeille} alt="abeille" className="w-12 h-12 mx-auto"/>
                        <p>©2024 - Corps d'Observation du Rhin</p>
                    </div>

                    {/* Third column (links) */}
                    <div>
                        <div className="flex flex-col justify-center">
                            <a href="/user" rel="noreferrer"className="mx-1 text-lg font-bold hover:text-frenchBrown hover:underline transition duration-300">Nos membres</a>
                            <a href="https://corpsdobservationdurhin.com/"  rel="noreferrer"   target="_blank" className="mx-1 text-lg font-bold hover:text-frenchBrown hover:underline transition duration-300">Ancien site internet</a>

                        </div>
                    </div>
                </div>
            </footer>
        );
    }
}