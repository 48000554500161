import './App.css';
import Header from './Header.js';
import Main from './Main.js';
import Footer from './Footer.js';


import React, {  } from "react";

import WebService from './Service.js';

/* Colors:
   gold : #ffd600
   blue : #072c50
   red : #cf0921
   white : #e8e2d8
*/


function App() {
    
    return (

            <Content />
    );
}

export default App;

export const Page = {
    Actualities: "home",
    Events: "event",
    Albums: "album",
    Troc: "troc",
    Login: "login",
    Register: "register",
    Terms: "terms",
    Profile: "user",
    Budget: "budget",
    Administration: "administration",
    Ticket: "bug",
    Documentation : "doc",
    Commands : "order",
    Shop : "shop",
    JoinUs : "join",
    Logistique : "logistique"
};



class Content extends React.Component {
    constructor(props) {
        super(props);
        let defaultPage  = window.location.pathname.split("/").pop() === "" ? Page.Actualities : window.location.pathname.split("/").pop();
        this.state = {
            page: defaultPage,
            user : null
        };
        
    }

    componentDidMount() {  
        this.setUser();
    }

    setUser() {
        if(WebService.getToken()) {
            let request = WebService.me();
            request.then((response) => {
                if (response.status === 200) {
                    response.json().then((data) => {
                        this.setState({user: data});
                        localStorage.setItem("currentUser", JSON.stringify(data));
                    });
                }
            });
        }  else {
            localStorage.removeItem("currentUser");
        }
    }

    getUser() {
        return JSON.parse(localStorage.getItem("currentUser"));
    }

    logout() {
        WebService.logout();
        this.setPage(Page.Actualities);
        this.setState({user: null});
        localStorage.removeItem("currentUser");
        window.location.reload();
    }

    setPage(page) {
        // Set the last element
        console.log("Set page to " + page);
        window.history.pushState({}, page, "/" + page);
        this.setState({page: page});
    }



    render() {
        // Make the footer STILL on the bottom
        return (
            <div className={"App font-ghandi tracking-wide	 bg-cover bg-fixed bg-[url('./images/banner.jpg')] "}>
            <Header 
                //unseenEvents={this.unseenEvents()}
                //pendingEvents={this.pendingEvents()}
                page={this.state.page} setPage={(page) => this.setPage(page)} user={this.state.user} logout={() => this.logout()} />
            
            <Main 
                  getUser={() => this.getUser()}
                  page={this.state.page} setPage={(page) => this.setPage(page)} setUser={() => this.setUser()} />


            <Footer />
        </div>
        );
    }
}
