import React from 'react';
// google_agenda.png
import google_agenda from './images/google_agenda.png';
class AddToCalendarButton extends React.Component {
  constructor(props) {
    super(props);
    
  }


  handleAddToCalendar = () => {
    console.log('Add to calendar button clicked');
    console.log(this.props);
    // Format of the date ISO 8601
    let startDate = new Date(this.props.start.dateTime);
    let endDate = new Date(this.props.end.dateTime);


    // https://calendar.google.com/calendar/r/eventedit?action=TEMPLATE&text=Birthday&dates=20201231T193000/20201231T223000&details=With clowns and stuff&location=North Pole
    const calendarUrl = 'https://calendar.google.com/calendar/render?action=TEMPLATE&text=' +
      encodeURIComponent(this.props.summary) +

      '&dates=' +
      startDate.toISOString().replace(/-|:|\.\d+/g, '').slice(0, -1) +
      '/' +
      endDate.toISOString().replace(/-|:|\.\d+/g, '').slice(0, -1) +

      '&details=' +
      encodeURIComponent(this.props.description) +

      '&location=' +
      encodeURIComponent(this.props.location);

    window.open(calendarUrl);
  };

  render() {
    return (
      <button onClick={this.handleAddToCalendar} className='flex flex-row text-center'>
        {/* // Reduce size */}
        <img src={google_agenda} alt="Google Agenda"className="text-center w-6 h-6" />

        <p className='pl-4 font-bold'>Ajouter à Google Agenda</p>
        </button>
    );
  }
}

export default AddToCalendarButton;